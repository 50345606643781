import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import RosaGasIcon from "../assets/img/icon.png";
import { useNavigate } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";

import { MyContext } from "../context/GlobalContextProvider";

const pages = [
    { name: "Inicio", route: "/" },
    { name: "Soporte", route: "support" },
];

const OnlyAuthenticationPage = [];

const OnlyAdminPages = [
    { name: "Usuarios", route: "users" },
    { name: "Centros de Distribución", route: "distribution-centers" },
    { name: "Productos", route: "products" },
    { name: "Pedidos", route: "orders" },
    { name: "Notificaciones", route: "notifications" }
    /* { name: "Transactions", route: "transactions" }, */
];

const OnlyAdminSales = [
    { name: "Pedidos", route: "orders" },
];

const OnlyAdminProducts = [
    { name: "Productos", route: "products" },
];

const OnlyAdminAndDeliveryPages = [{ name: "Pedidos", route: "orders" }];

/*const OnlyAdminAndCustomerPages = [{ name: "Transacciones", route: "transactions" }];*/
const OnlyAdminAndCustomerPages = [];

const OnlyAdminMarketingPages = [{ name: "Notificaciones", route: "notifications" }];

const settings = [
    { name: "Perfil", route: "account", ifAuthenticated: true },
    { name: "Salir", route: "exit", ifAuthenticated: true },
    { name: "Iniciar Sesión", route: "login", ifAuthenticated: false },
];

export function ResponsiveAppBar() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { states, resetStates } = useContext(MyContext);
    const { user } = states;

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (route = "") => {
        if (route !== "") {
            navigate(route);
        }

        setAnchorElNav(null);
    };

    const handleCloseUserMenu = (route = "") => {
        switch (route) {
            case "exit":
                Logout();
                break;

            case "login":
                navigate("login");
                break;

            case "account":
                navigate("account");
                break;

            default:
                break;
        }

        setAnchorElUser(null);
    };

    async function Logout() {
        await fetch("logout", { headers: { REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } })
            .then((response) => {
                response.text();
            })
            .then(() => {
                resetStates();
                navigate("/");
            });
    }

    /*    useEffect(() => {
        console.log("useEffect ResaponsiveAppBar User: ",user);
     }, [user]); */

    return (
        <AppBar position="sticky" color="inherit" sx={{ marginBottom: 2 }}>
            {/* <Container maxWidth="xl"></Container> */}
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" sx={{ padding: 0, display: { xs: "none", md: "flex" }, mr: 1 }}>
                        <img src={RosaGasIcon} alt="Logo" width="50" height="50" />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontWeight: 700,
                            color: "#ed0a8f",
                            textDecoration: "none",
                        }}
                    >
                        &nbsp;RosaGas
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.name}
                                    onClick={() => {
                                        handleCloseNavMenu(page.route);
                                    }}
                                >
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                            {user !== null && [
                                ...OnlyAuthenticationPage.map((page) => (
                                    <MenuItem
                                        key={page.name}
                                        onClick={() => {
                                            handleCloseNavMenu(page.route);
                                        }}
                                    >
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItem>
                                )),
                                ...(user.profileId === 1 || user.profileId === 2
                                    ? OnlyAdminAndCustomerPages.map((page) => (
                                        <MenuItem
                                            key={page.name}
                                            onClick={() => {
                                                handleCloseNavMenu(page.route);
                                            }}
                                        >
                                            <Typography textAlign="center">{page.name}</Typography>
                                        </MenuItem>
                                    ))
                                    : []),
                                ...(user.profileId === 1
                                    ? (() => {
                                        switch (user.username) {

                                            case "ventas@rosagas.com":
                                                return OnlyAdminSales.map((page) => (
                                                    <MenuItem
                                                        key={page.name}
                                                        onClick={() => {
                                                            handleCloseNavMenu(page.route);
                                                        }}
                                                    >
                                                        <Typography textAlign="center">{page.name}</Typography>
                                                    </MenuItem>
                                                ));
                                            case "manuel@nanogas.com":
                                                return OnlyAdminProducts.map((page) => (
                                                    <MenuItem
                                                        key={page.name}
                                                        onClick={() => {
                                                            handleCloseNavMenu(page.route);
                                                        }}
                                                    >
                                                        <Typography textAlign="center">{page.name}</Typography>
                                                    </MenuItem>
                                                ));
                                            default:
                                                return OnlyAdminPages.map((page) => (
                                                    <MenuItem
                                                        key={page.name}
                                                        onClick={() => {
                                                            handleCloseNavMenu(page.route);
                                                        }}
                                                    >
                                                        <Typography textAlign="center">{page.name}</Typography>
                                                    </MenuItem>
                                                ));
                                        }
                                    })()
                                    : []),
                                ...(user.profileId === 3
                                    ? OnlyAdminAndDeliveryPages.map((page) => (
                                        <MenuItem
                                            key={page.name}
                                            onClick={() => {
                                                handleCloseNavMenu(page.route);
                                            }}
                                        >
                                            <Typography textAlign="center">{page.name}</Typography>
                                        </MenuItem>
                                    ))
                                    : []),
                            ]}
                        </Menu>
                    </Box>

                    {/* <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit" sx={{ padding: 0, display: { xs: "flex", md: "none" } }}>
                  <img src={RosaGasIcon} alt="Logo" width={50} height={50} />
               </IconButton> */}

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    ></Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => {
                                    handleCloseNavMenu(page.route);
                                }}
                                sx={{ my: 2, color: "inherit", display: "block" }}
                            >
                                {page.name}
                            </Button>
                        ))}

                        {user !== null ? (
                            <>
                                {OnlyAuthenticationPage.map((page) => (
                                    <Button
                                        key={page.name}
                                        onClick={() => {
                                            handleCloseNavMenu(page.route);
                                        }}
                                        sx={{ my: 2, color: "inherit", display: "block" }}
                                    >
                                        {page.name}
                                    </Button>
                                ))}

                                {user.profileId === 1 || user.profileId === 2
                                    ? OnlyAdminAndCustomerPages.map((page) => (
                                        <Button
                                            key={page.name}
                                            onClick={() => {
                                                handleCloseNavMenu(page.route);
                                            }}
                                            sx={{ my: 2, color: "inherit", display: "block" }}
                                        >
                                            {page.name}
                                        </Button>
                                    ))
                                    : null}

                                {user.profileId === 1
                                    ? (() => {
                                        switch (user.username) {
                                            case "ventas@rosagas.com":
                                                return OnlyAdminSales.map((page) => (
                                                    <Button
                                                        key={page.name}
                                                        onClick={() => {
                                                            handleCloseNavMenu(page.route);
                                                        }}
                                                        sx={{ my: 2, color: "inherit", display: "block" }}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                ));
                                            case "manuel@nanogas.com":
                                                return OnlyAdminProducts.map((page) => (
                                                    <Button
                                                        key={page.name}
                                                        onClick={() => {
                                                            handleCloseNavMenu(page.route);
                                                        }}
                                                        sx={{ my: 2, color: "inherit", display: "block" }}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                ));
                                            default:
                                                return OnlyAdminPages.map((page) => (
                                                    <Button
                                                        key={page.name}
                                                        onClick={() => {
                                                            handleCloseNavMenu(page.route);
                                                        }}
                                                        sx={{ my: 2, color: "inherit", display: "block" }}
                                                    >
                                                        {page.name}
                                                    </Button>
                                                ));
                                        }
                                    })()
                                    : null}

                                {user.profileId === 3
                                    ? OnlyAdminAndDeliveryPages.map((page) => (
                                        <Button
                                            key={page.name}
                                            onClick={() => {
                                                handleCloseNavMenu(page.route);
                                            }}
                                            sx={{ my: 2, color: "inherit", display: "block" }}
                                        >
                                            {page.name}
                                        </Button>
                                    ))
                                    : null}
                            </>
                        ) : null}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {user !== null ? (
                            <Tooltip title="Open settings">
                                <IconButton size="large" onClick={handleOpenUserMenu} color="inherit">
                                    <PersonIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        ) : null}

                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {user !== null
                                ? settings.map((setting) =>
                                    setting.ifAuthenticated ? (
                                        <MenuItem
                                            key={setting.name}
                                            onClick={() => {
                                                handleCloseUserMenu(setting.route);
                                            }}
                                        >
                                            <Typography textAlign="center">{setting.name}</Typography>
                                        </MenuItem>
                                    ) : null
                                )
                                : settings.map((setting) =>
                                    setting.ifAuthenticated === false ? (
                                        <MenuItem
                                            key={setting.name}
                                            onClick={() => {
                                                handleCloseUserMenu(setting.route);
                                            }}
                                        >
                                            <Typography textAlign="center">{setting.name}</Typography>
                                        </MenuItem>
                                    ) : null
                                )}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
