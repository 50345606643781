export const ProfileValues = Object.freeze({ ADMIN: 1, CUSTOMER: 2, DELIVERYPARTNER: 3, DELIVERYPARTNERADMIN: 4, DELIVERYPARTNER100: 5 });

export const ProfileNames = {
    [ProfileValues.ADMIN]: "Admin",
    [ProfileValues.CUSTOMER]: "Cliente",
    [ProfileValues.DELIVERYPARTNER]: "Delivery",
    [ProfileValues.DELIVERYPARTNERADMIN]: "DeliveryAdmin",
    [ProfileValues.DELIVERYPARTNER100]: "Delivery100",
};

export const GetProfileName = (id) => {
    if (ProfileNames[id]) {
        return ProfileNames[id];
    } else {
        console.error(`Invalid ProfileId: ${id}`);
    }
};

export class Profile {
    constructor(id, name, description) {
        this.id = id;
        this.name = name;
        this.description = description;
    }
}
