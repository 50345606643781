import React, { useContext } from "react";
import { MyContext } from "../context/GlobalContextProvider";
import { UncontrolledCarousel } from "reactstrap";
import Container from "@mui/material/Container";
import imgRosa1 from "../assets/img/camion-rosagas-1.jpg";
import imgRosa2 from "../assets/img/camion-rosagas-2.jpg";
import imgRosa3 from "../assets/img/camion-rosagas-3.jpg";
import imgRosaGas from "../assets/img/rosagas.png";
import imgDownloadOnAppStoreBlack from "../assets/img/DownloadOnAppStore.png";
import imgDownloadOnPlayStore from "../assets/img/DownloadOnPlayStore.png";
import imgDownloadOnAppGallery from "../assets/img/DownloadOnAppGallery.png";
import imgRosaGasAppGalleryQR from "../assets/img/RosaGasAppGalleryQR.png";
import imgRosaGasAppStoreQR from "../assets/img/RosaGasAppStoreQR.png";
import imgRosaGasPlayStoreQR from "../assets/img/RosaGasPlayStoreQR.png";
import { Typography, Box, Divider, Grid, Paper, Link } from "@mui/material"; 
import { Facebook as FacebookIcon, Instagram as InstagramIcon, Email as EmailIcon } from "@mui/icons-material";

export const HomePage = () => {
    const { myStates } = useContext(MyContext);

    return (
        <>
            {/* <Container maxWidth="xl" className="mainContainerMinHeight"> */}
            <Container className="mainContainerMinHeight">
                {/*   
            <UncontrolledCarousel
               className="myCarousel"
               items={[
                  {
                     key: 1,
                     src: imgRosa1,
                     caption: "",
                  },
                  {
                     key: 2,
                     src: imgRosa2,
                     caption: "",
                  },
                  {
                     key: 3,
                     src: imgRosa3,
                     caption: "",
                  },
               ]}
            /> */}

                <Typography component="h1" variant="h4" gutterBottom style={{ color: "rgb(237, 10, 143)" }}>
                    RosaGas
                </Typography>

                <label className="form-label mb-4">Somos una empresa dedicada a la venta y distribución de cilindros de gas LP en Costa Rica.</label>
                <br />

                <label className="form-label">Dirección:</label>
                <br />

                <label className="form-label mb-4">SAN JOSE-SANTA ANA DISTRITO POZOS, DE LA ANTIGUA FABRICA DE EMPAQUES SANTA ANA, CIENTO CINCUENTA METROS AL ESTE Y TRESCIENTOS METROS AL SURESTE, EN OFICINAS ADMINISTRATIVAS DE LA EMPRESA LA CHUSPA.</label>
                <br />

                <label className="form-label">Teléfono:</label>
                <br />

                <label className="form-label">
                    <a href="https://wa.me/50689221111" className="text-decoration-none" target="_blank" rel="noreferrer noopener">
                        <i className="bi bi-whatsapp " style={{ color: "#25D366" }}></i>
                        {""} 8922 1111
                    </a>
                </label>
                <br />

                <label className="form-label">Correo:</label>
                <br />

                <label className="form-label">
                    <i className="bi bi-envelope"></i>
                    {""} ventas@rosagas.com
                </label>

                <br />
                <br />

                <Typography component="h1" variant="h4" textAlign="center" gutterBottom>
                    ¡Participa en Nuestros Sorteos Exclusivos!
                </Typography>
                <br />

                <label className="form-label mb-4">
                    ¿Te gustaría ganar premios increíbles? ¡Sigue nuestras redes sociales y mantente al día de nuestras promociones y sorteos exclusivos por usar nuestra aplicación móvil, darle like a la publicación del sorteo y compartirla en tú perfil!
                </label>
                <br />

                <Box display="flex" 
                    justifyContent="center">
                    <Link href="https://www.facebook.com/RosaGascr/" target="_blank" rel="noreferrer noopener" className="vibrate-1">
                        <FacebookIcon fontSize="large" />
                    </Link>
                    <Link href="https://www.instagram.com/rosagascr/" target="_blank" rel="noreferrer noopener" className="vibrate-1">
                        <InstagramIcon fontSize="large" />
                    </Link>
                </Box>

                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={4}>
                        <div style={{ textAlign: "center", margin: "10px auto" }}>
                            <Link href="https://apps.apple.com/cr/app/rosagas/id6448764369" target="_blank" className="vibrate-1">
                                <img src={imgRosaGasAppStoreQR} alt="App Store QR" style={{ width: "100%", maxWidth: "200px" }} />
                            </Link>
                        </div>
                        <div style={{ textAlign: "center", margin: "10px auto" }}>
                            <Link href="https://apps.apple.com/cr/app/rosagas/id6448764369" target="_blank" className="vibrate-1">
                                <img src={imgDownloadOnAppStoreBlack} alt="Download on App Store" style={{ width: "100%", maxWidth: "200px" }} />
                            </Link>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div style={{ textAlign: "center", margin: "10px auto" }}>
                            <Link href="https://play.google.com/store/apps/details?id=com.RosaGas.Wallet" target="_blank" className="vibrate-1">
                                <img src={imgRosaGasPlayStoreQR} alt="Play Store QR" style={{ width: "100%", maxWidth: "200px" }} />
                            </Link>
                        </div>
                        <div style={{ textAlign: "center", margin: "10px auto" }}>
                            <Link href="https://play.google.com/store/apps/details?id=com.RosaGas.Wallet" target="_blank" className="vibrate-1">
                                <img src={imgDownloadOnPlayStore} alt="Download on Play Store" style={{ width: "100%", maxWidth: "200px" }} />
                            </Link>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <div style={{ textAlign: "center", margin: "10px auto" }}>
                            <Link href="https://appgallery.huawei.com/app/C108961243" target="_blank" className="vibrate-1">
                                <img src={imgRosaGasAppGalleryQR} alt="App Gallery QR" style={{ width: "100%", maxWidth: "200px" }} />
                            </Link>
                        </div>
                        <div style={{ textAlign: "center", margin: "10px auto" }}>
                            <Link href="https://appgallery.huawei.com/app/C108961243" target="_blank" className="vibrate-1">
                                <img src={imgDownloadOnAppGallery} alt="Download on App Gallery" style={{ width: "100%", maxWidth: "200px" }} />
                            </Link>
                        </div>
                    </Grid>
                </Grid>

            </Container >
        </>
    );
};
