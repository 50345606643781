import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Container, Typography, Box } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { MyContext } from "../context/GlobalContextProvider";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OrderStats = () => {
    const [orderStats, setOrderStats] = useState({});
    const { states } = useContext(MyContext);
    const { orderDeliveryStatus } = states;

    useEffect(() => {
        const fetchOrderStats = async () => {
            try {
                const response = await axios.get("order", {
                    headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY },
                });
                const orders = response.data;

                const statusCount = orders.reduce((acc, order) => {
                    const status = orderDeliveryStatus.find(status => status.id === order.orderDeliveryStatusId)?.name || "Cancelado";
                    acc[status] = (acc[status] || 0) + 1;
                    return acc;
                }, {});

                setOrderStats(statusCount);
            } catch (error) {
                console.error(error);
            }
        };

        fetchOrderStats();
    }, [orderDeliveryStatus]);

    const data = {
        labels: Object.keys(orderStats),
        datasets: [
            {
                label: 'Estados de Pedidos',
                data: Object.values(orderStats),
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Estadísticas de Estados de Pedidos',
            },
        },
    };

    return (
        <Container maxWidth="xl">
            <Typography component="h2" align="center" variant="h4" m={1}>
                Estadísticas de Pedidos
            </Typography>
            <Box>
                <Bar data={data} options={options} />
            </Box>
        </Container>
    );
};

export default OrderStats;
