import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, TextField, Button, Checkbox, FormControlLabel, FormControl, Select, InputLabel, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import { SaveRounded } from "@mui/icons-material";

function createData(id, name, version, newVersion, typeId) {
   return { id, name, version, newVersion, typeId };
}

const rows = [createData(1, "RosaGasWallet", 1, 1.1, "droid"), createData(2, "RosaGasWallet", 1, 1.1, "iOS"), createData(3, "RosaGasDelivery", 1, 1.1, "droid"), createData(4, "RosaGasDelivery", 1, 1.1, "iOS")];

export const ApplicationManagerPage = () => {
   return (
      <Container maxWidth="xl">
         <Typography component="h1" variant="h3" align="center" color="text.primary" gutterBottom>
            Gestor de aplicaciones
         </Typography>

         <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
               <TableHead>
                  <TableRow>
                     <TableCell>id</TableCell>
                     <TableCell>Nombre</TableCell>
                     <TableCell>Versión Actual</TableCell>
                     <TableCell>Nueva Versión</TableCell>
                     <TableCell>Tipo</TableCell>
                     <TableCell></TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {rows.map((row) => (
                     <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                           {row.id}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.version}</TableCell>
                        <TableCell><TextField id="newVersion" label="Nueva Versión" size="small"/></TableCell>
                        <TableCell>{row.typeId}</TableCell>
                        <TableCell>
                           <IconButton aria-label="save" title="Guardar" color="primary">
                              <SaveRounded />
                           </IconButton>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </TableContainer>
      </Container>
   );
};
