import axios from "axios";

export async function GetUsers() {
   return new Promise((resolve, reject) => {
      axios
         .get("user/all", {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function GetUser(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`user/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function CreateUser(entity) {
   return new Promise((resolve, reject) => {
      axios
         .post(`user`, JSON.stringify(entity), {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function ResetPassword(username) {
   return new Promise((resolve, reject) => {
      axios
         .put(`user/resetpassword/${username}`, null, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}
