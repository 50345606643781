import axios from "axios";
 
export async function GetAllProvinces() {
   return new Promise((resolve, reject) => {
      axios
         .get(`province/all`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}
 
