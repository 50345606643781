import BaseService from "./BaseService";
import axios from "axios";

export async function GetDistributionCenter() {
   return new Promise((resolve, reject) => {
      axios
         .get(`distributioncenter/`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function GetAllDistributionCenters() {
   return new Promise((resolve, reject) => {
      axios
         .get(`distributioncenter/all`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function GetDistributionCenterById(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`distributioncenter/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export default class DistributionCenterService extends BaseService {
   static async get() {
      try {
         const service = new BaseService();
         return await service.get("distributioncenter");
      } catch (error) {
         return null;
      }
   }
}
