import React from 'react';
import { SnackbarProvider } from 'notistack';

export const SnackbarContext = React.createContext(null);

export const SnackbarProviderWrapper = ({ children }) => {
    const snackbar = React.useRef(null);

    return (
        <SnackbarContext.Provider value={snackbar}>
            <SnackbarProvider
                ref={(el) => (snackbar.current = el)}
                maxSnack={3}
            >
                {children}
            </SnackbarProvider>
        </SnackbarContext.Provider>
    );
};
