import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";
import Draggable from "react-draggable";

function PaperComponent(props) {
   return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
         <Paper {...props} />
      </Draggable>
   );
}

export default function DraggableDialog({ dialogTitle, dialogContentText, onDialogConfirm, button, showButtonText, buttonTextFalse = "Cancelar", buttonTextTrue = "Aceptar" }) {
   const [open, setOpen] = useState(false);

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = (confirmed) => () => {
      if (confirmed && onDialogConfirm) {
         onDialogConfirm();
      }
      setOpen(false);
   };

   return (
      <>
         {React.cloneElement(button, { onClick: handleClickOpen })}
         <Dialog open={open} onClose={handleClose(false)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title" variant="h5" >
               {dialogTitle}
            </DialogTitle>
            <DialogContent>
               <DialogContentText>{dialogContentText}</DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button autoFocus variant="outlined" color="error" onClick={handleClose(false)}>
                  {buttonTextFalse}
               </Button>
               <Button variant="outlined"  onClick={handleClose(true)}>{buttonTextTrue}</Button>
            </DialogActions>
         </Dialog>
      </>
   );
}
