import WhatsAppPng from "../assets/img/whatsapp.png";
import IconButton from "@mui/material/IconButton";

export const WhatsAppComponet = () => {
   return (
      <div style={{ position: "fixed", bottom: 0, right: 0, marginBottom: 5 }}>
         <IconButton aria-label="WhatsApp" href="https://api.whatsapp.com/send?phone=50689221111" target="_blank" rel="noopener noreferrer" title="WhastApp" className="bounce-in-top">
            <img alt="WhatsApp" src={WhatsAppPng} width={55} height={55} />
         </IconButton>
      </div>
   );
};
