import React, { useEffect, useState, useContext } from "react";
import { Container, Typography, Box, Toolbar, IconButton, InputBase, FormControl, OutlinedInput, InputLabel, InputAdornment, Checkbox, Button, Grid, FormControlLabel, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack } from "@mui/material";
import { Search as SearchIcon, Edit, AddRounded } from "@mui/icons-material";

import { styled, alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import axios from "axios";

import Loading from "../components/Loading";
import { DeleteIcon, AlarmIcon, AddShoppingCartIcon, RestartAltRounded } from "@mui/icons-material";
import { GetProfileName } from "../models/Profile";
import { useNavigate } from "react-router-dom";
import { GetUsers, ResetPassword } from "../services/UserService";
import DraggableDialog from "../components/DraggableDialog";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";

export const UsersPage = () => {
    const navigate = useNavigate();
    const snackbar = useContext(SnackbarContext);
    const [searchValue, setSearchValue] = useState("");
    const [users, setUsers] = useState(null);
    const [filter, setFilter] = useState("All");
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    async function LoadUser() {
        try {
            const response = await GetUsers();
            if (response.Result !== null) {
                //console.log(response.Result);
                setUsers(response.Result);
            }
        } catch (error) {
            // console.error(error);
        }
    }

    async function LoadObjects() {
        try {
            await LoadUser();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    async function handleResetPassword(username) {
        try {
            const response = await ResetPassword(username);
            if (response.Result !== null) {
                snackbar.current.enqueueSnackbar(`Se creó una contraseña temporal para ${username} y se envió al correo electrónico.`, {
                    variant: "success",
                });
            }
        } catch (error) { }
    }

    useEffect(() => {
        LoadObjects();
    }, []);

    /*    useEffect(() => {
       console.log("useEffect users: " + users);
    }, [users]); */

    return (
        <Container maxWidth="xl" className="mainContainerMinHeight">
            <Typography component="h2" align="center" variant="h4" margin={1}>
                Usuarios
            </Typography>

            <FormControl fullWidth variant="outlined" size="small" sx={{ marginTop: 1, marginBottom: 1 }}>
                <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-search"
                    onChange={handleSearchChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Buscar"
                />
            </FormControl>

            <IconButton
                title="Crear Usuario"
                color="primary"
                sx={{ marginBottom: 1 }}
                onClick={() => {
                    navigate("/user/0");
                }}
            >
                <AddRounded />
            </IconButton>

            {loading ? (
                <Loading />
            ) : users !== null ? (
                <>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Perfil</TableCell>
                                    <TableCell>Nombre de Usuario</TableCell>
                                    <TableCell align="center">Opcción</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => {
                                    if (search === "" || user.id.toString().includes(search) || GetProfileName(user.profileId).includes(search) || user.username.includes(search)) {
                                        return (
                                            <TableRow key={user.id}>
                                                <TableCell>{user.id}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {GetProfileName(user.profileId)}
                                                </TableCell>
                                                <TableCell>{user.username}</TableCell>
                                                <TableCell align="center">
                                                    {/* <IconButton
                                          title="Resetear contraseña"
                                          onClick={() => {
                                             const confirmed = window.confirm(`¿Estás seguro de que deseas resetear la contraseña del usuario ${user.username}?`);
                                             if (confirmed) {
                                                ResetPassword(user.username);
                                             }
                                          }}
                                       >
                                          <RestartAltRounded />
                                       </IconButton> */}

                                                    {/* <DraggableDialog dialogTitle="Resetear contraseña" dialogContentText={`¿Estás seguro de que deseas resetear la contraseña del usuario ${user.username}?`} onDialogClose={() => handleResetPassword(user.username)} buttonText="Sí" buttonIcon={<RestartAltRounded />} /> */}

                                                    <DraggableDialog
                                                        dialogTitle="Resetear contraseña"
                                                        dialogContentText={`¿Estás seguro de que deseas resetear la contraseña del usuario ${user.username}?`}
                                                        onDialogConfirm={() => handleResetPassword(user.username)}
                                                        button={
                                                            <IconButton title="Resetear contraseña" color="primary">
                                                                <RestartAltRounded />
                                                            </IconButton>
                                                        }
                                                        buttonText="Sí"
                                                        showButtonText={false}
                                                        buttonTextTrue="Sí"
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : null}
        </Container>
    );
};
