import BaseService from "./BaseService";
import axios from "axios";

export async function GetNotificationTypes() {
    try {
        const response = await axios.get("notificationtype", {
            headers: {
                "Content-Type": "application/json",
                REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
        });
        return response.data;
    } catch (error) { 
        return null
    }
}

export async function UpdateNotification(entity) {
    try {
        const response = await axios.put("notificationtype", JSON.stringify(entity), {
            headers: {
                "Content-Type": "application/json",
                REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
        });
        return response.data;
    } catch (error) {
        /* console.log(error); */
    }
}