export class DeliveryPartner {
   constructor(id, userId, active, distributionCenterId = "", identification, firstName, lastName, licensePlate, picture) {
      this.id = id;
      this.userId = userId;
      this.active = active;
      this.distributionCenterId = distributionCenterId;
      this.identification = identification;
      this.firstName = firstName;
      this.lastName = lastName;
      this.licensePlate = licensePlate;
      this.picture = picture;
   }
}
