import axios from "axios";

export async function GetAllCantonsByProvinceId(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`canton/all/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}
