export class DistributionCenter {
   constructor({ id = 0, name = "", provinceId = "", cantonId = "", districtId = "", avenue = "", street = "", address = "", latitude = 0, longitude = 0, phoneNumber = "", openingHours = "L - V 7:30am - 5pm, S 7:30am - 4pm", active = true } = {}) {
      this.id = id;
      this.name = name;
      this.provinceId = provinceId;
      this.cantonId = cantonId;
      this.districtId = districtId;
      this.avenue = avenue;
      this.street = street;
      this.address = address;
      this.latitude = latitude;
      this.longitude = longitude;
      this.phoneNumber = phoneNumber;
      this.openingHours = openingHours;
      this.active = active;
   }
}
