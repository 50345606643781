import { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { MyContext } from "../context/GlobalContextProvider";
import { Container, Typography, Grid, Button, TextField, Stack, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { FileUploadRounded } from "@mui/icons-material";
import { TransactionStatus, TransactionStatusValues } from "../models/TransactionStatus";
import { GetCustomer, GetCustomerByIdentification, UpdateCustomerBalance } from "../services/CustomerService";
import { CreateTransaction, GetTransaction, UpdateTransaction } from "../services/TransactionService";
import { Transaction } from "../models/Transaction";
import { Customer } from "../models/Customer";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";
import { AlertDialogContext } from "../components/AlertDialogSlide/AlertDialogContext";

export const TransactionPage = () => {
   const navigate = useNavigate();
   const { transactionId } = useParams();
   const { openAlertDialog } = useContext(AlertDialogContext);

   const snackbar = useContext(SnackbarContext);
   const [loading, setLoading] = useState(false);
   const [transaction, setTransaction] = useState(null);
   const [customerTransaction, setCustomerTransaction] = useState(null);
   const [useEffectCount, setUseEffectCount] = useState(0);
   const [phoneNumber, setPhoneNumber] = useState("");
   const [entitySource, setEntitySource] = useState("");

   const { states } = useContext(MyContext);
   const { user } = states;

   async function HandleGetCustomerByIdentification(identification) {
      try {
         var response = await GetCustomerByIdentification(identification);
         if (response.Result !== null) {
            setCustomerTransaction(response.Result);
            setTransaction((prevTransaction) => ({ ...prevTransaction, customerId: response.Result.id }));
         } else {
            snackbar.current.enqueueSnackbar("Indentificación del cliente no existe", {
               variant: "error",
            });
         }
      } catch {}
   }

   async function HandleUpdateCustomerBalance() {
      try {
         var success = true;
         var responseGetCustomer = await GetCustomerByIdentification(customerTransaction.identification);

         if (responseGetCustomer.Result !== null) {
            responseGetCustomer.Result.balance = parseFloat(responseGetCustomer.Result.balance) + parseFloat(transaction.amount);

            var responseUpdateCustomerBalance = await UpdateCustomerBalance(responseGetCustomer.Result);
            if (responseUpdateCustomerBalance.Result !== null) {
               snackbar.current.enqueueSnackbar("Éxito al actualizar el balance del cliente", {
                  variant: "success",
               });
            } else {
               snackbar.current.enqueueSnackbar("Error al actualizar el balance del cliente", {
                  variant: "error",
               });
               success = false;
            }
         } else {
            snackbar.current.enqueueSnackbar("La indentificación del cliente no existe en nuestra base de datos", {
               variant: "error",
            });
            success = false;
         }
      } catch {
         success = false;
      } finally {
         return success;
      }
   }

   async function HandleUpdateTransaction(entityTransaction = null) {
      try {
         var response;
         if (entityTransaction === null) {
            response = await UpdateTransaction(transaction);
         } else {
            response = await UpdateTransaction(entityTransaction);
         }
         var success = true;
         if (response.Result !== null) {
            snackbar.current.enqueueSnackbar("Éxito al actualizar la Transacción", {
               variant: "success",
            });
         } else {
            snackbar.current.enqueueSnackbar(`Error al actualizar la Transacción`, {
               variant: "error",
            });
            success = false;
         }
      } catch {
         success = false;
      } finally {
         return success;
      }
   }
   async function HandleCreateTransaction(updateBalance) {
      try {
         var response = await CreateTransaction(transaction);
         if (response.Result !== null) {
            snackbar.current.enqueueSnackbar("Éxito al crear la Transacción", {
               variant: "success",
            });

            if (updateBalance) {
               var reponseUpdateCustomerBalance = await HandleUpdateCustomerBalance();
               if (reponseUpdateCustomerBalance) {
                  var reponseUpdateTransaction = await HandleUpdateTransaction(response.Result);
                  if (reponseUpdateTransaction) navigate("/transactions");
               }
            } else {
               navigate("/transactions");
            }
         } else {
            snackbar.current.enqueueSnackbar(`Error al crear la Transacción`, { variant: "error" });
         }
      } catch (ex) {
         snackbar.current.enqueueSnackbar(`Hubo un error, por favor intentelo de nuevo`, {
            variant: "error",
         });
      }
   }

   async function HandleSubmit(e) {
      e.preventDefault();
      setLoading(true);

      try {
         var success = true;
         var responseAlert = false;
         if (transaction.transactionStatusId === TransactionStatusValues.APPLIED) {
            responseAlert = await new Promise((resolve) => {
               openAlertDialog({
                  title: "¿Actualizar el balance del cliente?",
                  content: "¡Alerta!",
                  disagreeText: "No",
                  agreeText: "Sí",
                  resolve,
               });
            });
         }

         if (transactionId === "0") success = await HandleCreateTransaction(responseAlert);
         else {
            if (responseAlert) if (success) success = await HandleUpdateCustomerBalance();
            if (success) success = await HandleUpdateTransaction();
            if (success) navigate("/transactions");
         }
      } catch {
      } finally {
         setLoading(false);
      }
   }

   async function LoadTransaction(id) {
      const response = await GetTransaction(id);
      if (response.Result !== null) { 
         setTransaction(response.Result);
         setPhoneNumber(response.Result.description.match(/\d+/)[0]);
         setEntitySource(response.Result.description.match(/@ (.+)/)[1]);
         const responseCustomer = await LoadCustomer(response.Result.customerId);
         if (response.Result !== null) {
            setCustomerTransaction(responseCustomer.Result);
         }
      }
   }

   async function LoadCustomer(id) {
      const response = await GetCustomer(id);
      if (response.Result !== null) setCustomerTransaction(response.Result);
   }

   async function LoadObjects() {
      try {
         if (transactionId === "0") {
            setTransaction(new Transaction());
            setCustomerTransaction(new Customer());
         } else {
            await LoadTransaction(transactionId);
         }
      } catch {
      } finally {
         setLoading(false);
      }
   }

   const handleUpload = (event) => {
      const file = event.target.files[0];

      if (file) {
         const reader = new FileReader();
         reader.onloadend = () => {
            setTransaction((prevTransaction) => ({ ...prevTransaction, proofOfTransactionImageBase64: reader.result }));
         };
         reader.readAsDataURL(file);
      }
   };

   const HandleDescription = (varPhoneNumer, varEntitySource) => {
      setPhoneNumber(varPhoneNumer);
      setEntitySource(varEntitySource);
      var description = `${varPhoneNumer} @ ${varEntitySource}`;
      setTransaction((prevTransaction) => ({ ...prevTransaction, description: description }));
   };

   useEffect(() => {
      if (useEffectCount === 1) {
         setUseEffectCount(useEffectCount + 1);
      }
      if (user !== null && (user.profileId === 1 || user.profileId === 2)) {
         LoadObjects();
      } else {
         navigate("/login");
      }
   }, []);

   return (
      <Container maxWidth="xl" className="mainContainerMinHeight">
         <Typography component="h2" align="center" variant="h4" margin={1}>
            Transacción
         </Typography>

         {loading ? (
            <Loading />
         ) : (
            <form onSubmit={HandleSubmit}>
               {transaction === null || customerTransaction === null ? null : (
                  <>
                     <TextField
                        id="identification"
                        label="Número de identificación del Cliente"
                        variant="outlined"
                        size="small"
                        fullWidth
                        required
                        type="number"
                        defaultValue={customerTransaction.identification}
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                           HandleGetCustomerByIdentification(e.target.value);
                        }}
                        disabled={transactionId !== "0"}
                     />

                     <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                           <Stack spacing={2}>
                              <TextField
                                 id="reference"
                                 label="Número de referencia"
                                 variant="outlined"
                                 size="small"
                                 fullWidth
                                 required
                                 type="number"
                                 defaultValue={transaction.reference}
                                 onChange={(e) => {
                                    setTransaction((prevTransaction) => ({ ...prevTransaction, reference: e.target.value }));
                                 }}
                                 disabled={transactionId !== "0"}
                              />

                              <TextField
                                 id="document"
                                 label="Documento"
                                 variant="outlined"
                                 size="small"
                                 type="number"
                                 fullWidth
                                 required
                                 defaultValue={transaction.document}
                                 onChange={(e) => {
                                    setTransaction((prevTransaction) => ({ ...prevTransaction, document: e.target.value }));
                                 }}
                              />

                              <TextField
                                 id="phoneNumber"
                                 label="Teléfono origen"
                                 variant="outlined"
                                 size="small"
                                 type="number"
                                 fullWidth
                                 required
                                 defaultValue={phoneNumber}
                                 onChange={(e) => {
                                    HandleDescription(e.target.value, entitySource);
                                 }}
                                 disabled={transactionId !== "0"}
                              />

                              <TextField
                                 id="nameOnTransaction"
                                 label="Nombre cliente origen"
                                 variant="outlined"
                                 size="small"
                                 fullWidth
                                 required
                                 defaultValue={transaction.nameOnTransaction}
                                 onChange={(e) => {
                                    setTransaction((prevTransaction) => ({ ...prevTransaction, nameOnTransaction: e.target.value }));
                                 }}
                                 disabled={transactionId !== "0"}
                              />

                              <TextField
                                 id="sourceEntity"
                                 label="Entidad origen"
                                 variant="outlined"
                                 size="small"
                                 fullWidth
                                 required
                                 defaultValue={entitySource}
                                 onChange={(e) => {
                                    HandleDescription(phoneNumber, e.target.value);
                                 }}
                                 disabled={transactionId !== "0"}
                              />

                              <TextField
                                 id="ammount"
                                 label="Monto"
                                 variant="outlined"
                                 size="small"
                                 type="number"
                                 fullWidth
                                 required
                                 defaultValue={transaction.amount}
                                 onChange={(e) => {
                                    setTransaction((prevTransaction) => ({ ...prevTransaction, amount: e.target.value }));
                                 }}
                                 disabled={transactionId !== "0"}
                              />

                              <TextField id="description" label="Descripción" variant="outlined" size="small" fullWidth required value={transaction.description} disabled={true} />

                              <TextField
                                 id="transactionDate"
                                 label="Fecha de Transacción (mm/dd/yy)"
                                 variant="outlined"
                                 size="small"
                                 type="datetime-local"
                                 fullWidth
                                 required
                                 defaultValue={transaction.dateCreated}
                                 onChange={(e) => {
                                    setTransaction((prevTransaction) => ({ ...prevTransaction, dateCreated: e.target.value }));
                                 }}
                                 disabled={transactionId !== "0"}
                              />

                              <FormControl component="fieldset">
                                 <RadioGroup
                                    row
                                    aria-label="transactionStatus"
                                    name="transactionStatus"
                                    value={transaction.transactionStatusId}
                                    onChange={(e) => {
                                       setTransaction((prevTransaction) => ({ ...prevTransaction, transactionStatusId: parseInt(e.target.value) }));
                                    }}
                                 >
                                    {Object.values(TransactionStatusValues).map((status) => (
                                       <FormControlLabel key={status} value={status} control={<Radio />} label={TransactionStatus.getSpanishName(status)} />
                                    ))}
                                 </RadioGroup>
                              </FormControl>
                           </Stack>
                        </Grid>

                        <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
                           {transaction.proofOfTransactionImageBase64 && (
                              <div className="w-100">
                                 {/* <img className="img-fluid mx-auto d-block mb-4 rounded shadow" src={transaction.proofOfTransactionImage} alt="Transaction" width="400" /> */}
                                 <img className="img-fluid mx-auto d-block mb-4 rounded shadow" src={transaction.proofOfTransactionImageBase64} alt="Transaction" />
                              </div>
                           )}

                           {transaction.proofOfTransactionImage && (
                              <div className="w-100">
                                 {/* <img className="img-fluid mx-auto d-block mb-4 rounded shadow" src={transaction.proofOfTransactionImage} alt="Transaction" width="400" /> */}
                                 <img className="img-fluid mx-auto d-block mb-4 rounded shadow" src={`data:image;base64,${transaction.proofOfTransactionImage}`} alt="Transaction" />
                              </div>
                           )}

                           <Button variant="contained" component="label" endIcon={<FileUploadRounded />} disabled={transactionId !== "0"}>
                              Cargar Imagen
                              <input type="file" hidden accept="image/*" onChange={handleUpload} />
                           </Button>
                        </Grid>

                        <Grid item xs={12} md={6}>
                           <Button
                              fullWidth
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                 navigate("/transactions");
                              }}
                           >
                              Cancelar
                           </Button>
                        </Grid>

                        <Grid item xs={12} md={6}>
                           <Button fullWidth variant="contained" type="submit">
                              {transactionId === "0" ? "Crear" : "Actualizar"}
                           </Button>
                        </Grid>
                     </Grid>
                  </>
               )}
            </form>
         )}
      </Container>
   );
};
