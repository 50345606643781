import BaseService from "./BaseService";
import axios from "axios";

export async function LoadAllProducts() {
   try {
      const response = await axios.get("product/all", {
         headers: {
            "Content-Type": "application/json",
            REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
         },
      });
      return response.data;
   } catch (error) {
      //console.log(error);
   }
}

export async function LoadProducts() {
   try {
      const response = await axios.get("product", {
         headers: {
            "Content-Type": "application/json",
            REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
         },
      });
      return response.data;
   } catch (error) {
      /* console.log(error); */
   }
}

export async function UpdateProduct(product) {
   try {
      const response = await axios.put("product", JSON.stringify(product), {
         headers: {
            "Content-Type": "application/json",
            REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
         },
      });
      return response.data;
   } catch (error) {
      /* console.log(error); */
   }
}

export default class ProductService extends BaseService {
   static async get() {
      try {
         const service = new BaseService();
         return await service.get("product");
      } catch (error) {
         return null;
      }
   }
}
