import axios from "axios";

class BaseService {
    async fetchData(url, customHeaders) {
        try {
            await fetch(url, { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw response;
                })
                .then((data) => {
                    return data;
                });
        } catch (error) {
            /* console.error(error); */
        }
    }

    async get(url, customHeaders) {
        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
                    ...customHeaders,
                },
            });
            return response;
        } catch (error) {
            return null;
        }
    }

    async update(url, customHeaders) {
        try {
            const response = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
                    ...customHeaders,
                },
            });
            return response;
        } catch (error) {
            return null;
        }
    }
}

export default BaseService;
