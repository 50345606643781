import BaseService from "./BaseService";

export default class customerLocationService extends BaseService {
   static async getAll() {
      try {
         const baseService = new BaseService();
         return await baseService.get("customerlocation");
      } catch (error) {
         return null;
      }
   }

   static async getById(id) {
      try {
         const baseService = new BaseService();
         return await baseService.get(`customerlocation/${id}`);
      } catch (error) {
         return null;
      }
   }
}
