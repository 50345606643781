import React, { createContext, useState } from 'react';
import AlertDialogSlide from './AlertDialogSlide';

export const AlertDialogContext = createContext();

export function AlertDialogProvider({ children }) {
    const [alertDialog, setAlertDialog] = useState({
        isOpen: false,
        title: '',
        content: '',
        disagreeText: '',
        agreeText: '',
        resolve: null,
    });

    const openAlertDialog = (config) => {
        setAlertDialog({ ...config, isOpen: true });
    };

    const closeAlertDialog = (result) => {
        if (alertDialog.resolve) {
            alertDialog.resolve(result);
        }
        setAlertDialog({ ...alertDialog, isOpen: false });
    };

    return (
        <AlertDialogContext.Provider value={{ openAlertDialog, closeAlertDialog }}>
            {children}
            <AlertDialogSlide {...alertDialog} onClose={closeAlertDialog} />
        </AlertDialogContext.Provider>
    );
}
