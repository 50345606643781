import React from "react";
import { createTheme } from "@mui/material/styles";

export const CustomTheme = createTheme({
   palette: {
      primary: {
         main: "#ed0a8f",
      },
   },
   typography: {
      h1: { color: "#ed0a8f" },
      h2: { color: "#ed0a8f" },
      h3: { color: "#ed0a8f" },
      h4: { color: "#ed0a8f" },
      h5: { color: "#ed0a8f" },
      h6: { color: "#ed0a8f" },
      title: {
         color: "#ed0a8f",
      },
   },
});
