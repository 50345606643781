import React, { useContext } from "react";

import orderDeliveryStatusService from "../services/OrderDeliveryStatusService";
import distributionCenterService from "../services/DistributionCenterService";
import productService from "../services/ProductService";

export const GetGlobalData = async (context, profileId) => {
   let response;

   try {
      response = await orderDeliveryStatusService.get();
      if (response != null && response.status === 200) {
         context.updateStates({ orderDeliveryStatus: response.data });
      }
   } catch (error) {
      /* console.error("Error:", error); */
   }

   try {
      response = await distributionCenterService.get();
      if (response != null && response.status === 200) {
         context.updateStates({ distributionCenter: response.data.Result });
      }
   } catch (error) {
      /* console.error("Error:", error); */
   }

   try {
      response = await productService.get();
      if (response != null && response.status === 200) {
         context.updateStates({ products: response.data });
      }
   } catch (error) {
      /* console.log("Error:", error); */
   }
};
