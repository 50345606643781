import React, { useEffect, useState, useContext } from "react";
import { Container, Typography, Box, Toolbar, IconButton, InputBase, FormControl, OutlinedInput, InputLabel, InputAdornment, Checkbox, Button, Grid, FormControlLabel, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, TextField } from "@mui/material";
import { Search as SearchIcon, Edit, AddRounded, EditRounded, SendRounded, DeleteRounded, DeleteIcon, AlarmIcon, AddShoppingCartIcon, RestartAltRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import OrderService from "../services/OrderService";
import { LoadAllProducts, UpdateProduct } from "../services/ProductService";

import DraggableDialog from "../components/DraggableDialog";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";
import { OrderType } from "../models/OrderType";

export const ProductsPage = () => {
    const [listProducts, setListProducts] = useState(null);
    const [listOrders, setListOrders] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterProductStatus, setFilterProductStatus] = useState("All");
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const snackbar = useContext(SnackbarContext);

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    async function LoadObjects() {
        try {
            const products = await LoadAllProducts();
            if (products !== undefined) setListProducts(products);
        } catch (error) {
            snackbar.current.enqueueSnackbar("Error en la carga de los productos", {
                variant: "error",
            });
        } finally {
            setLoading(false);
        }
    }

    function updateObject(obj, key, value) {
        return {
            ...obj,
            [key]: value,
        };
    }

    async function HandleChange(product, key, value) {
        try {
            if (product[key] != value) {
                const updatedProduct = updateObject(product, key, value);
                const response = await UpdateProduct(updatedProduct);
                if (response !== undefined) {
                    setListProducts((prevProducts) => prevProducts.map((p) => (p.id === updatedProduct.id ? updatedProduct : p)));
                    snackbar.current.enqueueSnackbar(`Producto con el id ${product.id} actualizado`, {
                        variant: "success",
                    });
                }
            }
        } catch (error) {
            snackbar.current.enqueueSnackbar("Error al actualizar el producto", {
                variant: "error",
            });
        }
    }

    useEffect(() => {
        LoadObjects();
    }, []);

    return (
        <Container maxWidth="xl" className="mainContainerMinHeight">
            <Typography component="h2" align="center" variant="h4" m={1}>
                Productos
            </Typography>

            <FormControl fullWidth variant="outlined" size="small" sx={{ marginTop: 1, marginBottom: 1 }}>
                <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-search"
                    onChange={handleSearchChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton edge="end">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Buscar"
                />
            </FormControl>

            <IconButton
                title="Crear"
                color="primary"
                sx={{ marginBottom: 1 }}
                onClick={() => {
                    /* navigate("/user/0"); */
                }}
            >
                <AddRounded />
            </IconButton>

            {listProducts === null ? (
                loading ? (
                    <Loading />
                ) : (
                    <p>No hay productos</p>
                )
            ) : (
                <>
                    {/* <Button variant="outlined" endIcon={<EditRounded,  />} sx={{ marginTop: 1, marginBottom: 3 }}>
                  Editar todos los productos
               </Button> */}

                    <TableContainer component={Paper}>
                        <Table aria-label="Products" size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Precio</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Estado</TableCell>
                                    {/* <TableCell align="center">Opcción</TableCell> */}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listProducts.map((item) => {
                                    if (search === "" || item.id.toString().includes(search) || item.name.includes(search) || item.price.toString().includes(search) || item.description.includes(search)) {
                                        return (
                                            <TableRow key={item.id}>
                                                <TableCell component="th" scope="row">
                                                    {item.id}
                                                </TableCell>

                                                <TableCell>{OrderType.getSpanishName(item.orderTypeId)}</TableCell>

                                                <TableCell>
                                                    <TextField
                                                        label="Nombre"
                                                        size="small"
                                                        defaultValue={item.name}
                                                        onBlur={async (e) => {
                                                            await HandleChange(item, "name", e.target.value);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Precio"
                                                        size="small"
                                                        defaultValue={item.price}
                                                        type="number"
                                                        onBlur={async (e) => {
                                                            await HandleChange(item, "price", e.target.value);
                                                        }}
                                                        inputProps={{
                                                            onWheel: (e) => e.target.blur()
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Descripción"
                                                        size="small"
                                                        defaultValue={item.description}
                                                        onBlur={async (e) => {
                                                            await HandleChange(item, "description", e.target.value);
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={item.active}
                                                                size="small"
                                                                onChange={async (e) => {
                                                                    await HandleChange(item, "active", e.target.checked);
                                                                }}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={item.active ? "Activo" : "Inactivo"}
                                                    />
                                                </TableCell>

                                                {/* <TableCell align="center">
                                       <IconButton title="Editar Producto" color="primary">
                                          <EditRounded />
                                       </IconButton>
                                    </TableCell> */}
                                            </TableRow>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Container>
    );
};
