import React from "react";
import { GlobalStyles } from "@mui/system";

export function CustomGlobalStyles() {
   return (
      <GlobalStyles
         styles={(theme) => ({
            ".mainContainerMinHeight": {
               [theme.breakpoints.down("xs")]: {
                  minHeight: "0",
               },
               [theme.breakpoints.up("sm")]: {
                  minHeight: "calc(100vh - 32px - 70px - 140px)",
               },
            },
         })}
      />
   );
}
