import * as React from "react";
import { Typography, Box, Divider, Link as MuiLink, Container } from "@mui/material";

const LegalPage = () => {
   return (
      <Container maxWidth="xl">
         <Typography component="h1" variant="h2" align="center" color="text.primary" gutterBottom>
            Aviso Legal
         </Typography>
         <br />
         <Typography>El presente Aviso Legal regula las condiciones de uso de la aplicación de RosaGas Costa Rica, S.A.</Typography>
         <br />
         <Typography>Al acceder y utilizar nuestra aplicación, usted acepta todas las condiciones incluidas en el presente Aviso Legal y se compromete a cumplir con nuestras políticas de privacidad.</Typography>
         <br />
         <Typography fontSize="Title">1. Objeto y ámbito de aplicación</Typography>
         <br />
         <Typography>Este Aviso Legal establece los términos y condiciones bajo los cuales RosaGas ofrece el acceso y uso de su aplicación. Este Aviso Legal también define los derechos y obligaciones que se derivan de su uso.</Typography>
         <br />
         <Typography>El Usuario reconoce que RosaGas no será responsable por cualquier uso incorrecto de la aplicación, ni por daños o perjuicios que pudieran derivarse del mismo.</Typography>
         <br />
         <Typography fontSize="Title">2. Modificaciones</Typography>
         <br />
         <Typography>RosaGas se reserva el derecho de modificar este Aviso Legal en cualquier momento, haciendo público los cambios en la sección correspondiente de la aplicación. Los cambios entrarán en vigor en el momento de su publicación.</Typography>
         <br />
         <Typography>Es responsabilidad del Usuario revisar periódicamente este Aviso Legal para conocer cualquier cambio. El uso continuado de la aplicación después de la publicación de las modificaciones implica la aceptación de estas.</Typography>
         <br />
         <Typography fontSize="Title">3. Uso de la Aplicación</Typography>
         <br />
         <Typography>El Usuario se compromete a utilizar la aplicación de acuerdo con las leyes y reglamentaciones vigentes, la moral y buenas costumbres, el orden público y lo establecido en este Aviso Legal.</Typography>
         <br />
         <Typography fontSize="Title">4. Propiedad Intelectual e Industrial</Typography>
         <br />
         <Typography>Todos los contenidos y elementos accesibles a través de la aplicación (imágenes, sonidos, textos, logos, códigos, diseño, entre otros) son propiedad de RosaGas o de terceros que han autorizado su uso. El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial de RosaGas.</Typography>
         Protección de Datos
         <br />
         <Typography fontSize="Title">5. Protección de Datos</Typography>
         <br />
         <Typography>En cumplimiento de las normativas vigentes en materia de protección de datos personales, todos los datos personales proporcionados durante la utilización de la aplicación serán tratados de acuerdo con lo establecido en la Política de Privacidad de RosaGas, que todo Usuario debe aceptar explícitamente para poder utilizar la aplicación.</Typography>
         <br />
         <Typography fontSize="Title">6. Limitación de responsabilidad</Typography>
         <br />
         <Typography>RosaGas no garantiza la disponibilidad, acceso y continuidad del funcionamiento de la aplicación y sus servicios, por lo que no será responsable, dentro de los límites establecidos en el ordenamiento jurídico vigente, de los daños y perjuicios causados al Usuario como consecuencia de la indisponibilidad, fallos de acceso y falta de continuidad de la aplicación y sus servicios.</Typography>
         <br />
         <Typography fontSize="Title">7. Legislación y Jurisdicción aplicable</Typography>
         <br />
         <Typography>Este Aviso Legal se regirá e interpretará de acuerdo con las leyes de Costa Rica. Cualquier conflicto que pudiera surgir entre RosaGas y los Usuarios se someterá a los tribunales del domicilio del Usuario.</Typography>
         <br />
         <Typography>Para cualquier consulta acerca de estas condiciones puede ponerse en contacto con nosotros a través de los canales de atención al Usuario publicados en nuestra aplicación.</Typography>
         <br />
         <Typography>Fecha de última actualización: 06/03/23</Typography>
      </Container>
   );
};

export default LegalPage;
