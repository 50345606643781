export const TransactionStatusValues = Object.freeze({ CREATED: 1, REVISED: 2, REJECTED: 3, APPLIED: 4 });

const statusNames = {
   [TransactionStatusValues.CREATED]: "Creada",
   [TransactionStatusValues.REVISED]: "Revisada",
   [TransactionStatusValues.REJECTED]: "Rechazada",
   [TransactionStatusValues.APPLIED]: "Aplicada",
};

export class TransactionStatus {
   constructor(id, name) {
      this.id = id;
      this.name = name;
   }

   static getSpanishName(status) {
      if (statusNames[status]) {
         return statusNames[status];
      } else {
         throw new Error(`Invalid status: ${status}`);
      }
   }
}
