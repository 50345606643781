import * as React from "react";
import { Link } from "react-router-dom";
import { Typography, Box, Divider, Link as MuiLink, Container } from "@mui/material";
import { Facebook as FacebookIcon, Instagram as InstagramIcon, Email as EmailIcon } from "@mui/icons-material";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" {...props}>
            {"Copyright © "}

            <MuiLink component={Link} to="/">
                RosaGas
            </MuiLink>

            {" " + new Date().getFullYear()}
            {". Todos los derechos reservados."}
        </Typography>
    );
}

function SocialNetworks(props) {
    return (
        <Box>
            <MuiLink href="https://www.facebook.com/RosaGascr/" target="_blank" rel="noreferrer noopener" className="vibrate-1">
                <FacebookIcon fontSize="large" />
            </MuiLink>
            <MuiLink href="https://www.instagram.com/rosagascr/" target="_blank" rel="noreferrer noopener" className="vibrate-1">
                <InstagramIcon fontSize="large" />
            </MuiLink>
        </Box>
    );
}

const Footer = () => {
    return (
        <footer>
            <Box sx={{ gap: 3 }} display="flex" flexDirection="column">
                <Container maxWidth="xl" sx={{ marginBottom: 3 }}>
                    <Divider sx={{ marginTop: 3 }} />



                    <Box textAlign="center" sx={{ marginTop: 3, display: { lg: "flex" }, justifyContent: { xs: "center", lg: "space-between" } }}>

                        <div className="me-auto"> 
                        </div>

                        <SocialNetworks />
                    </Box>

                    <Box textAlign="center" sx={{ marginTop: 3, display: { lg: "flex" }, justifyContent: { xs: "center", lg: "space-between" } }}>
                        <Copyright />
                        <Typography variant="body2">
                            <MuiLink component={Link} to="/privacy">
                                Política de privacidad
                            </MuiLink>
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            <MuiLink component={Link} to="/terms">
                                Condiciones de uso
                            </MuiLink>
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            <MuiLink component={Link} to="/legal">
                                Aviso legal
                            </MuiLink>
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </footer>
    );
};

export default Footer;
