import * as React from "react";
import { Typography, Container } from "@mui/material";

const PrivacyPage = () => {
   return (
      <Container maxWidth="xl">
         <Typography component="h1" variant="h3" align="center" color="text.primary" gutterBottom>
            Política de Privacidad
         </Typography>

         <div className="gmail_default" style={{ fontSize: "small", color: "#0b5394" }}>
            <p className="MsoNormal" style={{ margin: "0in 0in 7.5pt", lineHeight: "18pt", fontSize: "11pt", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>ROSA GAS SOCIEDAD ANONIMA recaba algunos Datos Personales de sus Usuarios.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>

            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios pueden estar sujetos a diferentes estándares de protección y, por lo tanto, a algunos se les pueden aplicar estándares más amplios. </font>
                     <font style={{ verticalAlign: "inherit" }}>Para conocer más sobre los criterios de protección, los Usuarios pueden consultar la sección de aplicabilidad.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este documento se puede imprimir como referencia utilizando el comando de impresión en la configuración de cualquier navegador.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>

            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>TITULAR Y RESPONSABLE DEL TRATAMIENTO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ margin: "0in 0in 8pt", fontSize: "11pt", background: "white" }}>
               <span style={{ fontSize: "10.5pt", background: "white", color: "black" }} lang="ES-CR">
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>ROSA GAS SOCIEDAD ANÓNIMA</font>
                  </font>
               </span>
               <span style={{ fontSize: "10.5pt", background: "white" }} lang="ES-CR">
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ margin: "0in 0in 8pt", fontSize: "11pt", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }} lang="ES-CR">
                  <br />
               </span>
               <span style={{ fontSize: "12pt", color: "black" }} lang="ES-CR">
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>SAN JOSE-SANTA ANA DISTRITO POZOS, DE LA ANTIGUA FABRICA DE EMPAQUES SANTA ANA, CIENTO CINCUENTA METROS AL ESTE Y TRESCIENTOS METROS AL SURESTE, EN OFICINAS ADMINISTRATIVAS DE LA EMPRESA LA CHUSPA</font>
                  </font>
               </span>
               <span style={{ fontSize: "12pt" }} lang="ES-CR">
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ margin: "0in", fontSize: "11pt", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "black" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Santa Ana, San José 10903</font>
                  </font>
               </span>
               <span style={{ fontSize: "12pt" }}>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ margin: "0in", fontSize: "11pt", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "black" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Costa Rica</font>
                  </font>
               </span>
               <span style={{ fontSize: "12pt" }}>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <b>
                  <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Correo electrónico de contacto del propietario: </font>
                     </font>
                  </span>
               </b>
               <font style={{ verticalAlign: "inherit" }}>
                  <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                     <a href="mailto:ventas@rosagascr.com" target="_blank" rel="noreferrer">
                        <font style={{ verticalAlign: "inherit" }}>ventas@rosagascr.com</font>
                     </a>
                  </span>
               </font>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  &nbsp;
                  <a href="mailto:ventas@rosagascr.com" target="_blank" rel="noreferrer">
                     <font style={{ verticalAlign: "inherit" }} />
                  </a>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>TIPOS DE DATOS RECOGIDOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Dentro de los tipos de Datos Personales que ROSA GAS SOCIEDAD ANONIMA recaba, por sí misma oa través de terceros, se encuentran: Datos de Uso; </font>
                     <font style={{ verticalAlign: "inherit" }}>Rastreador; </font>
                     <font style={{ verticalAlign: "inherit" }}>dirección de correo electrónico; </font>
                     <font style={{ verticalAlign: "inherit" }}>número de teléfono; </font>
                     <font style={{ verticalAlign: "inherit" }}>nombre de pila; </font>
                     <font style={{ verticalAlign: "inherit" }}>apellido; </font>
                     <font style={{ verticalAlign: "inherit" }}>varios tipos de Datos; </font>
                     <font style={{ verticalAlign: "inherit" }}>contraseña; </font>
                     <font style={{ verticalAlign: "inherit" }}>Datos comunicados durante el uso del servicio.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los detalles completos sobre cada tipo de Datos personales recopilados se proporcionan en las secciones dedicadas de esta política de privacidad o en los textos de explicación específicos que se muestran antes de la recopilación de Datos. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos Personales pueden ser proporcionados libremente por el Usuario, o, en el caso de los Datos de Uso, recopilados automáticamente al utilizar ROSA GAS SOCIEDAD ANONIMA. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Salvo que se especifique lo contrario, todos los Datos solicitados por ROSA GAS SOCIEDAD ANONIMA son obligatorios y la falta de suministro de estos Datos puede hacer que ROSA GAS SOCIEDAD ANONIMA no pueda prestar sus servicios. </font>
                     <font style={{ verticalAlign: "inherit" }}>En los casos en que ROSA GAS SOCIEDAD ANONIMA establezca específicamente que algunos Datos no son obligatorios, los Usuarios son libres de no comunicar estos Datos sin consecuencias para la disponibilidad o el funcionamiento del Servicio.</font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios que no estén seguros de qué Datos personales son obligatorios pueden ponerse en contacto con el Titular. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Cualquier uso de Cookies -o de otras herramientas de seguimiento- por parte de ROSA GAS SOCIEDAD ANONIMA o de los titulares de servicios de terceros utilizados por ROSA GAS SOCIEDAD ANONIMA tiene como finalidad la prestación del Servicio requerido por el Usuario, además de cualquier otra finalidad descrita en el presente documento y en la Política de Cookies, si está disponible.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios son responsables de los Datos Personales de terceros obtenidos, publicados o compartidos a través de ROSA GAS SOCIEDAD ANÓNIMA y confirman que cuentan con el consentimiento del tercero para proporcionar los Datos al Titular.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>MODO Y LUGAR DEL TRATAMIENTO DE LOS DATOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>MÉTODOS DE PROCESAMIENTO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
                  <br />
                  The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of ROSA GAS SOCIEDAD ANONIMA (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  LEGAL BASIS OF PROCESSING
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  The Owner may process Personal Data relating to Users if one of the following applies:
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     processing is necessary for compliance with a legal obligation to which the Owner is subject;
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  PLACE
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos se procesan en las oficinas operativas del Titular y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. </font>
                  </font>
                  <br />
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Dependiendo de la ubicación del Usuario, las transferencias de datos pueden implicar la transferencia de los Datos del Usuario a un país diferente al suyo. </font>
                     <font style={{ verticalAlign: "inherit" }}>Para obtener más información sobre el lugar de procesamiento de dichos Datos transferidos, los Usuarios pueden consultar la sección que contiene detalles sobre el procesamiento de Datos personales.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Si se aplican estándares de protección más amplios, los Usuarios también tienen derecho a conocer la base legal de las transferencias de Datos a un país fuera de la Unión Europea o a cualquier organización internacional regida por el derecho internacional público o establecida por dos o más países, como la ONU. , y sobre las medidas de seguridad adoptadas por el Titular para salvaguardar sus Datos. </font>
                  </font>
                  <br />
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Si se lleva a cabo dicha transferencia, los Usuarios pueden obtener más información consultando las secciones correspondientes de este documento o consultar con el Propietario utilizando la información proporcionada en la sección de contacto.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>TIEMPO DE RETENCIÓN</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos Personales se procesarán y almacenarán durante el tiempo que lo requiera el propósito para el que fueron recopilados.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Por lo tanto:</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Los Datos Personales recopilados para fines relacionados con la ejecución de un contrato entre el Titular y el Usuario se conservarán hasta que dicho contrato se haya ejecutado en su totalidad.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Los Datos Personales recopilados para los fines de los intereses legítimos del Titular se conservarán mientras sea necesario para cumplir con dichos fines. </font>
                        <font style={{ verticalAlign: "inherit" }}>Los usuarios pueden encontrar información específica sobre los intereses legítimos perseguidos por el Titular en las secciones correspondientes de este documento o poniéndose en contacto con el Titular.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>El Propietario puede conservar los Datos personales durante un período más largo siempre que el Usuario haya dado su consentimiento para dicho procesamiento, siempre que dicho consentimiento no sea retirado. </font>
                     <font style={{ verticalAlign: "inherit" }}>Además, el Titular puede estar obligado a conservar los Datos Personales por un período mayor cuando así lo requiera para el cumplimiento de una obligación legal o por orden de una autoridad. </font>
                  </font>
                  <br />
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Una vez que expire el período de retención, los Datos personales se eliminarán. </font>
                     <font style={{ verticalAlign: "inherit" }}>Por tanto, el derecho de acceso, el derecho de supresión, el derecho de rectificación y el derecho a la portabilidad de los datos no podrán ser ejercitados una vez transcurrido el plazo de conservación.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>LAS FINALIDADES DEL TRATAMIENTO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos relativos al Usuario son recabados para permitir al Titular prestar su Servicio, cumplir con sus obligaciones legales, responder a solicitudes de ejecución, proteger sus derechos e intereses (o los de sus Usuarios o de terceros), detectar cualquier actividad maliciosa o fraudulenta, así como lo siguiente: visualización de contenido de plataformas externas, administración de etiquetas, contacto con el usuario, análisis, administración de contactos y envío de mensajes, manejo de pagos, monitoreo de infraestructura, hospedaje e infraestructura backend, administración de base de datos de usuarios, conexión de datos, rendimiento de contenido y pruebas de características (A/B testing), Transferencia de datos fuera de la UE, Registro y autenticación proporcionados directamente por ROSA GAS SOCIEDAD ANONIMA, Interacción con plataformas de chat en vivo, Registro y autenticación, Remarketing y segmentación por comportamiento, Protección contra SPAM,Servicios de plataforma y hosting, Publicidad y Optimización y distribución de tráfico.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Para información específica sobre los Datos Personales utilizados para cada finalidad, el Usuario podrá consultar la sección “Información detallada sobre el tratamiento de Datos Personales”.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INFORMACIÓN DETALLADA SOBRE EL TRATAMIENTO DE DATOS PERSONALES</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los datos personales se recopilan para los siguientes propósitos y utilizando los siguientes servicios:</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>PUBLICIDAD</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite utilizar los Datos del Usuario para fines de comunicación publicitaria. </font>
                     <font style={{ verticalAlign: "inherit" }}>Estas comunicaciones se muestran en forma de banners y otros anuncios en ROSA GAS SOCIEDAD ANONIMA, posiblemente en función de los intereses del Usuario. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Esto no significa que todos los Datos personales se utilicen para este fin. </font>
                     <font style={{ verticalAlign: "inherit" }}>La información y las condiciones de uso se muestran a continuación. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Algunos de los servicios que se enumeran a continuación pueden utilizar Trackers para identificar a los Usuarios o pueden utilizar la técnica de retargeting conductual, es decir, mostrar anuncios adaptados a los intereses y el comportamiento del Usuario, incluidos los detectados fuera de ROSA GAS SOCIEDAD ANÓNIMA. </font>
                     <font style={{ verticalAlign: "inherit" }}>Para obtener más información, consulte las políticas de privacidad de los servicios correspondientes.</font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los servicios de este tipo suelen ofrecer la posibilidad de excluirse de dicho seguimiento. </font>
                     <font style={{ verticalAlign: "inherit" }}>Además de cualquier función de exclusión voluntaria ofrecida por cualquiera de los servicios a continuación, los Usuarios pueden obtener más información sobre cómo excluirse generalmente de la publicidad basada en intereses en la sección dedicada "Cómo excluirse de la publicidad basada en intereses" en este documento. .</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>JSDELIVR ( </font>
                     </font>
                     <a href="http://jsdelivr.com" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=http://jsdelivr.com&source=gmail&ust=1686240229978000&usg=AOvVaw3xUikuAY2QnVPwpxT8M5xb">
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>jsdelivr.com</font>
                        </font>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> ) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>JSDELIVR es un servicio de publicidad proporcionado por </font>
                     </font>
                     <a href="http://jsdelivr.com" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=http://jsdelivr.com&source=gmail&ust=1686240229978000&usg=AOvVaw3xUikuAY2QnVPwpxT8M5xb">
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>jsdelivr.com</font>
                        </font>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> . </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net&source=gmail&ust=1686240229978000&usg=AOvVaw3zBOEOcdcBXZidpTIDWJNW">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>ANALÍTICA</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los servicios contenidos en esta sección permiten al Titular monitorear y analizar el tráfico web y pueden usarse para realizar un seguimiento del comportamiento del Usuario.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Google Analytics (Google Ireland Limited)
                     <br />
                     Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of ROSA GAS SOCIEDAD ANONIMA, to prepare reports on its activities and share them with other Google services.
                     <br />
                     Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
                     <br />
                     Personal Data processed: Tracker; Usage Data.
                     <br />
                     Place of processing: Ireland –&nbsp;
                     <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229978000&usg=AOvVaw1GGUsI4Ypp2xeVqqqpVb8R">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     &nbsp;–&nbsp;
                     <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout?hl%3Den&source=gmail&ust=1686240229978000&usg=AOvVaw1gO-Pb_5uz2mx-AmXnw7gt">
                        <span style={{ color: "rgb(68,138,255)" }}>Opt Out</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Attribution (Attribution, LLC)
                     <br />
                     Attribution is an analytics service provided by Attribution, LLC.
                     <br />
                     Personal Data processed: Tracker; Usage Data.
                     <br />
                     Place of processing: United States –&nbsp;
                     <a href="https://www.attributionapp.com/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.attributionapp.com/privacy/&source=gmail&ust=1686240229978000&usg=AOvVaw3oRdNrPthr-xx_hb6Foy6h">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Facebook Ads conversion tracking (Facebook pixel) (Facebook Ireland Ltd)
                     <br />
                     Facebook Ads conversion tracking (Facebook pixel) is an analytics service provided by Facebook Ireland Ltd that connects data from the Facebook advertising network with actions performed on ROSA GAS SOCIEDAD ANONIMA. The Facebook pixel tracks conversions that can be attributed to ads on Facebook, Instagram and Audience Network.
                     <br />
                     Personal Data processed: Tracker; Usage Data.
                     <br />
                     Place of processing: Ireland –&nbsp;
                     <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/about/privacy/&source=gmail&ust=1686240229978000&usg=AOvVaw3hzWka6pwa9w1fHDrJUXxJ">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     MixPanel (MixPanel)
                     <br />
                     MixPanel is an analytics service provided by Mixpanel Inc.
                     <br />
                     Personal Data processed: Tracker; Usage Data.
                     <br />
                     Place of processing: United States –&nbsp;
                     <a href="https://mixpanel.com/legal/privacy-policy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://mixpanel.com/legal/privacy-policy/&source=gmail&ust=1686240229979000&usg=AOvVaw1rrzSZ6hfPr5cg2Nj82659">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     &nbsp;–&nbsp;
                     <a href="https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information%23optout-users&source=gmail&ust=1686240229979000&usg=AOvVaw3XAXj5SlWODRdWf8rmMMOl">
                        <span style={{ color: "rgb(68,138,255)" }}>Opt Out</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  CONNECTING DATA
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  This type of service allows the Owner to connect Data with third-party services disclosed within this privacy policy.
                  <br />
                  This results in Data flowing through these services, potentially causing the retention of this Data.
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Zapier (Zapier, Inc.)
                     <br />
                     Zapier is a workflow automation service provided by Zapier, Inc. that automates the movement of Data between (third-party) services.
                     <br />
                     Personal Data processed: Tracker.
                     <br />
                     Place of processing: United States –&nbsp;
                     <a href="https://zapier.com/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://zapier.com/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw1yh8rUUNa99un-EjMHP0NK">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  CONTACTING THE USER
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Mailing list or newsletter (ROSA GAS SOCIEDAD ANONIMA)
                     <br />
                     By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning ROSA GAS SOCIEDAD ANONIMA. Your email address might also be added to this list as a result of signing up to ROSA GAS SOCIEDAD ANONIMA or after making a purchase.
                     <br />
                     Personal Data processed: Tracker.
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Contact form (ROSA GAS SOCIEDAD ANONIMA)
                     <br />
                     By filling in the contact form with their Data, the User authorizes ROSA GAS SOCIEDAD ANONIMA to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
                     <br />
                     Personal Data processed: email address; first name; last name.
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  CONTENT PERFORMANCE AND FEATURES TESTING (A/B TESTING)
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  The services contained in this section allow the Owner to track and analyze the User response concerning web traffic or behavior regarding changes to the structure, text or any other component of ROSA GAS SOCIEDAD ANONIMA.
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google Optimize (Google Ireland Limited) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google Optimize es un servicio de pruebas A/B proporcionado por Google Ireland Limited ("Google"). </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google puede utilizar Datos Personales para contextualizar y personalizar los anuncios de su propia red publicitaria. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                     </font>
                     <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw1eBuN_mqL2GG2cap4xIcab">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>TRANSFERENCIA DE DATOS FUERA DE LA UE</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>El Propietario puede transferir Datos personales recopilados dentro de la UE a terceros países (es decir, cualquier país que no sea parte de la UE) solo de conformidad con una base legal específica. </font>
                     <font style={{ verticalAlign: "inherit" }}>Cualquier transferencia de datos de este tipo se basa en una de las bases legales que se describen a continuación. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios pueden consultar con el Propietario para saber qué base legal se aplica a qué servicio específico.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Transferencia de datos al exterior en base a cláusulas contractuales tipo (ROSA GAS SOCIEDAD ANONIMA) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Si esta es la base legal, la transferencia de Datos Personales desde la UE a terceros países la realiza el Titular según “cláusulas contractuales tipo” proporcionadas por la Comisión Europea . </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Esto significa que los destinatarios de los datos se han comprometido a procesar los datos personales de conformidad con los estándares de protección de datos establecidos por la legislación de protección de datos de la UE. </font>
                        <font style={{ verticalAlign: "inherit" }}>Para más información, se ruega a los Usuarios que se pongan en contacto con el Titular a través de los datos de contacto facilitados en el presente documento. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: varios tipos de Datos.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>MOSTRAR CONTENIDO DE PLATAFORMAS EXTERNAS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio le permite visualizar contenidos alojados en plataformas externas directamente desde las páginas de ROSA GAS SOCIEDAD ANONIMA e interactuar con ellas. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio aún puede recopilar datos de tráfico web para las páginas donde está instalado el servicio, incluso cuando los Usuarios no lo utilizan.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google Fonts (Google Ireland Limited) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google Fonts es un servicio de visualización de tipografías prestado por Google Ireland Limited que permite a ROSA GAS SOCIEDAD ANONIMA incorporar contenidos de este tipo en sus páginas. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Datos de Uso; </font>
                        <font style={{ verticalAlign: "inherit" }}>varios tipos de Datos como se especifica en la política de privacidad del servicio.</font>
                     </font>
                     <br />
                  </span>
                  <div>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                        </font>
                        <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw1eBuN_mqL2GG2cap4xIcab">
                           <span style={{ color: "rgb(68,138,255)" }}>
                              <font style={{ verticalAlign: "inherit" }}>
                                 <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                              </font>
                           </span>
                        </a>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}> .</font>
                        </font>
                        <u />
                        &nbsp;
                        <u />
                     </span>
                  </div>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INFRAESTRUCTURA DE ALOJAMIENTO Y BACKEND</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio tiene el propósito de alojar Datos y archivos que permiten que ROSA GAS SOCIEDAD ANONIMA se ejecute y se distribuya, así como proporcionar una infraestructura preparada para ejecutar funciones específicas o partes de ROSA GAS SOCIEDAD ANONIMA.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Algunos servicios entre los enumerados a continuación, si los hay, pueden funcionar a través de servidores distribuidos geográficamente, lo que dificulta determinar la ubicación real donde se almacenan los Datos personales.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Amazon Web Services (AWS) (Amazon Web Services, Inc.) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Amazon Web Services (AWS) es un servicio de alojamiento y back-end proporcionado por Amazon Web Services, Inc. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos personales procesados: diversos tipos de datos, tal como se especifica en la política de privacidad del servicio. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Singapur –&nbsp; </font>
                     </font>
                     <a href="https://aws.amazon.com/compliance/data-privacy-faq/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://aws.amazon.com/compliance/data-privacy-faq/&source=gmail&ust=1686240229979000&usg=AOvVaw05BpfUSPXBMN2J-5wiV1te">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>MONITOREO DE INFRAESTRUCTURA</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite a ROSA GAS SOCIEDAD ANÓNIMA monitorear el uso y comportamiento de sus componentes para mejorar su desempeño, operación, mantenimiento y solución de problemas. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Qué Datos Personales son tratados depende de las características y modo de implementación de estos servicios, cuya función es filtrar las actividades de ROSA GAS SOCIEDAD ANONIMA.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Sentry (Functional Software, Inc.) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Sentry es un servicio de monitoreo proporcionado por Functional Software, Inc.. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales procesados: varios tipos de Datos como se especifica en la política de privacidad del servicio. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://sentry.io/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://sentry.io/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw2cW2nrCROO4o_5vmLf7g0a">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INTERACCIÓN CON PLATAFORMAS DE CHAT EN VIVO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite a los Usuarios interactuar con plataformas de chat en vivo de terceros directamente desde las páginas de ROSA GAS SOCIEDAD ANONIMA, con el fin de contactar y ser contactado por el servicio de soporte de ROSA GAS SOCIEDAD ANONIMA. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Si uno de estos servicios está instalado, puede recopilar Datos de navegación y Uso en las páginas donde está instalado, incluso si los Usuarios no usan activamente el servicio. </font>
                     <font style={{ verticalAlign: "inherit" }}>Además, las conversaciones de chat en vivo pueden registrarse.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Chat de clientes de Facebook Messenger (Facebook Ireland Ltd) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>El chat de clientes de Facebook Messenger es un servicio para interactuar con la plataforma de chat en vivo de Facebook Messenger proporcionado por Facebook Ireland Ltd. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos personales procesados: datos comunicados durante el uso del servicio; </font>
                        <font style={{ verticalAlign: "inherit" }}>Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                     </font>
                     <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/about/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw1pcWONjUxO-D0jPLUWjWKM">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>ROSA GAS SOCIEDAD ANONIMA Widget </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>ROSA GAS SOCIEDAD ANONIMA es una plataforma de mensajería empresarial que conecta a los clientes de cualquier canal con todos los equipos.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>GESTIÓN DE CONTACTOS Y ENVÍO DE MENSAJES</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite gestionar una base de datos de contactos de correo electrónico, contactos telefónicos o cualquier otra información de contacto para comunicarse con el Usuario. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Estos servicios también pueden recopilar datos sobre la fecha y la hora en que el Usuario vio el mensaje, así como cuando el Usuario interactuó con él, como al hacer clic en los enlaces incluidos en el mensaje.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Customer.io (Peaberry Software Inc.) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Customer.io es un servicio de administración de direcciones de correo electrónico y envío de mensajes proporcionado por Peaberry Software Inc. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos personales procesados: dirección de correo electrónico; </font>
                        <font style={{ verticalAlign: "inherit" }}>Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://customer.io/legal/privacy-policy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://customer.io/legal/privacy-policy/&source=gmail&ust=1686240229979000&usg=AOvVaw3cYtGZBhrGWKUB3do645MZ">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Sendgrid (Sendgrid) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Sendgrid es un servicio de gestión de direcciones de correo electrónico y envío de mensajes proporcionado por Sendgrid Inc. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos personales procesados: dirección de correo electrónico; </font>
                        <font style={{ verticalAlign: "inherit" }}>nombre de pila; </font>
                        <font style={{ verticalAlign: "inherit" }}>apellido. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://sendgrid.com/policies/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://sendgrid.com/policies/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw25PmFG9xVSAds-KBdNPHHW">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>SERVICIOS DE PLATAFORMA Y HOSTING</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Estos servicios tienen el propósito de alojar y ejecutar componentes clave de ROSA GAS SOCIEDAD ANONIMA, por lo tanto, permiten la prestación de ROSA GAS SOCIEDAD ANONIMA desde una plataforma unificada. </font>
                     <font style={{ verticalAlign: "inherit" }}>Dichas plataformas brindan una amplia gama de herramientas al Propietario, por ejemplo, análisis, registro de usuarios, comentarios, administración de bases de datos, comercio electrónico, procesamiento de pagos, que implican la recopilación y el manejo de Datos personales. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Algunos de estos servicios funcionan a través de servidores distribuidos geográficamente, lo que dificulta determinar la ubicación real donde se almacenan los Datos personales.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Webflow (Webflow, Inc.) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Webflow es una plataforma provista por Webflow, Inc. que permite al Titular construir, ejecutar y hospedar ROSA GAS SOCIEDAD ANÓNIMA. </font>
                        <font style={{ verticalAlign: "inherit" }}>Webflow es altamente personalizable y puede alojar sitios web desde simples blogs hasta complejas plataformas de comercio electrónico. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Datos de Uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://webflow.com/legal/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://webflow.com/legal/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw0NkfPfjYz27TY1xtK_d9p0">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> &nbsp;–&nbsp; </font>
                     </font>
                     <a href="https://webflow.com/dashboard/unsubscribe" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://webflow.com/dashboard/unsubscribe&source=gmail&ust=1686240229979000&usg=AOvVaw0eXftFz8CXsbbM6a4rb633">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Optar por no participar</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>REGISTRO Y AUTENTICACIÓN</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Al registrarse o autenticarse, los Usuarios permiten a ROSA GAS SOCIEDAD ANONIMA identificarlos y darles acceso a los servicios dedicados. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Dependiendo de lo que se describe a continuación, terceros pueden proporcionar servicios de registro y autenticación. </font>
                     <font style={{ verticalAlign: "inherit" }}>En este caso, ROSA GAS SOCIEDAD ANONIMA podrá acceder a algunos Datos, almacenados por estos servicios de terceros, con fines de registro o identificación. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Algunos de los servicios enumerados a continuación también pueden recopilar Datos personales para fines de selección y creación de perfiles; </font>
                     <font style={{ verticalAlign: "inherit" }}>para obtener más información, consulte la descripción de cada servicio.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google OAuth (Google Ireland Limited) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google OAuth es un servicio de registro y autenticación proporcionado por Google Ireland Limited y está conectado a la red de Google. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales procesados: varios tipos de Datos como se especifica en la política de privacidad del servicio. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                     </font>
                     <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw1eBuN_mqL2GG2cap4xIcab">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>REGISTRO Y AUTENTICACIÓN PROPORCIONADA DIRECTAMENTE POR ROSA GAS SOCIEDAD ANONIMA</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Al registrarse o autenticarse, los Usuarios permiten a ROSA GAS SOCIEDAD ANONIMA identificarlos y darles acceso a los servicios dedicados. </font>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos personales se recopilan y almacenan únicamente con fines de registro o identificación. </font>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos recabados son únicamente los necesarios para la prestación del servicio solicitado por los Usuarios.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Registro directo (ROSA GAS SOCIEDAD ANONIMA) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>El Usuario se registra rellenando el formulario de registro y facilitando los Datos Personales directamente a ROSA GAS SOCIEDAD ANONIMA. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos personales tratados: dirección de correo electrónico; </font>
                        <font style={{ verticalAlign: "inherit" }}>nombre de pila; </font>
                        <font style={{ verticalAlign: "inherit" }}>apellido; </font>
                        <font style={{ verticalAlign: "inherit" }}>contraseña.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>REMARKETING Y OBJETIVO POR COMPORTAMIENTO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite a ROSA GAS SOCIEDAD ANONIMA y a sus socios informar, optimizar y servir publicidad basada en el uso pasado de ROSA GAS SOCIEDAD ANONIMA por parte del Usuario. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Esta actividad se facilita mediante el seguimiento de los Datos de uso y el uso de Rastreadores para recopilar información que luego se transfiere a los socios que administran la actividad de remarketing y orientación por comportamiento. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Algunos servicios ofrecen una opción de remarketing basada en listas de direcciones de correo electrónico. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Además de cualquier función de exclusión proporcionada por cualquiera de los servicios a continuación, los usuarios pueden optar por no participar visitando la&nbsp; </font>
                  </font>
                  <a href="http://optout.networkadvertising.org/?c=1" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=http://optout.networkadvertising.org/?c%3D1&source=gmail&ust=1686240229979000&usg=AOvVaw1AS3PIJYssv_P5MK2byXfO">
                     <span style={{ color: "rgb(68,138,255)" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>página de exclusión de Network Advertising Initiative</font>
                        </font>
                     </span>
                  </a>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}> .</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <b>
                  <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Los usuarios también pueden optar por no recibir ciertas funciones publicitarias a través de la configuración del dispositivo aplicable, como la configuración de publicidad del dispositivo para teléfonos móviles o la configuración de anuncios en general</font>
                     </font>
                  </span>
               </b>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}> .</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google Ads Remarketing (Google Ireland Limited) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google Ads Remarketing es un servicio de remarketing y de segmentación por comportamiento prestado por Google Ireland Limited que conecta la actividad de ROSA GAS SOCIEDAD ANONIMA con la red publicitaria de Google Ads y la Cookie de DoubleClick. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Los usuarios pueden rechazar el uso de cookies de Google para la personalización de anuncios visitando la&nbsp; </font>
                     </font>
                     <a href="https://adssettings.google.com/authenticated" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://adssettings.google.com/authenticated&source=gmail&ust=1686240229979000&usg=AOvVaw0z7a8NogT4kuN_p6UJtj0L">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Configuración de anuncios</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> de Google . </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                     </font>
                     <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw1eBuN_mqL2GG2cap4xIcab">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> &nbsp;–&nbsp; </font>
                     </font>
                     <a href="https://adssettings.google.com/authenticated#display_optout" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://adssettings.google.com/authenticated%23display_optout&source=gmail&ust=1686240229979000&usg=AOvVaw2FWEwrNqAUtFjlTWo0xpd_">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Optar por no participar</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Facebook Remarketing (Facebook Ireland Ltd) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Facebook Remarketing es un servicio de remarketing y de segmentación por comportamiento proporcionado por Facebook Ireland Ltd que conecta la actividad de ROSA GAS SOCIEDAD ANONIMA con la red de publicidad de Facebook. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                     </font>
                     <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.facebook.com/about/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw1pcWONjUxO-D0jPLUWjWKM">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> &nbsp;–&nbsp; </font>
                     </font>
                     <a href="https://www.aboutads.info/choices/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.aboutads.info/choices/&source=gmail&ust=1686240229979000&usg=AOvVaw35wx5OpZkbGWMZuI5Ou4Kg">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Optar por no participar</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>PROTECCIÓN CONTRA EL SPAM</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio analiza el tráfico de ROSA GAS SOCIEDAD ANÓNIMA, que potencialmente contenga Datos Personales de los Usuarios, con el fin de filtrarlo de partes del tráfico, mensajes y contenidos que sean reconocidos como SPAM.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google reCAPTCHA (Google Ireland Limited) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Google reCAPTCHA es un servicio de protección contra correo no deseado proporcionado por Google Ireland Limited. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>El uso de reCAPTCHA está sujeto a la&nbsp; </font>
                     </font>
                     <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/policies/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw0gnn10epMuCBkIXwvm4MZ7">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> &nbsp;y&nbsp; </font>
                     </font>
                     <a href="https://www.google.com/intl/en/policies/terms/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/intl/en/policies/terms/&source=gmail&ust=1686240229979000&usg=AOvVaw0B_DiuKD-TSbsqymZAxXTq">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>los términos de uso</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> de Google . </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Rastreador; </font>
                        <font style={{ verticalAlign: "inherit" }}>Datos de uso. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Irlanda –&nbsp; </font>
                     </font>
                     <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw1eBuN_mqL2GG2cap4xIcab">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>GESTIÓN DE ETIQUETAS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio ayuda al Titular a gestionar de forma centralizada los tags o scripts necesarios en ROSA GAS SOCIEDAD ANONIMA. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Esto da como resultado que los Datos de los Usuarios fluyan a través de estos servicios, lo que podría resultar en la retención de estos Datos.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Google Tag Manager (Google Ireland Limited)
                     <br />
                     Google Tag Manager is a tag management service provided by Google Ireland Limited.
                     <br />
                     Personal Data processed: Usage Data.
                     <br />
                     Place of processing: Ireland –&nbsp;
                     <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://policies.google.com/privacy&source=gmail&ust=1686240229979000&usg=AOvVaw1eBuN_mqL2GG2cap4xIcab">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     Segment (Segment Inc.)
                     <br />
                     Segment is a tag management service provided by Segment.io, Inc.
                     <br />
                     Personal Data processed: Tracker; Usage Data.
                     <br />
                     Place of processing: United States –&nbsp;
                     <a href="https://segment.com/docs/legal/privacy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://segment.com/docs/legal/privacy/&source=gmail&ust=1686240229979000&usg=AOvVaw2-sRX9QOfCsHbBtS_xCYxO">
                        <span style={{ color: "rgb(68,138,255)" }}>Privacy Policy</span>
                     </a>
                     .<u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  OPTIMIZACIÓN Y DISTRIBUCIÓN DEL TRÁFICO
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite a ROSA GAS SOCIEDAD ANÓNIMA distribuir sus contenidos a través de servidores ubicados en diferentes países y optimizar su rendimiento. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los datos personales que se procesan dependen de las características y la forma en que se implementan estos servicios. </font>
                     <font style={{ verticalAlign: "inherit" }}>Su función es filtrar las comunicaciones entre ROSA GAS SOCIEDAD ANONIMA y el navegador del Usuario. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Dada la amplia distribución de este sistema, es difícil determinar los lugares a los que se transfieren los contenidos que pueden contener Información Personal del Usuario.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Cloudflare (Cloudflare Inc.) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Cloudflare es un servicio de optimización y distribución de tráfico proporcionado por Cloudflare Inc. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>La forma en que se integra Cloudflare significa que filtra todo el tráfico a través de ROSA GAS SOCIEDAD ANONIMA, es decir, la comunicación entre ROSA GAS SOCIEDAD ANONIMA y el navegador del Usuario, al tiempo que permite recoger datos analíticos de ROSA GAS SOCIEDAD ANÓNIMA. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos Personales tratados: Rastreador. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://www.cloudflare.com/privacypolicy/&source=gmail&ust=1686240229980000&usg=AOvVaw1trJR3ASDtxGDVn9OfyTgj">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "22.5pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "12pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>GESTIÓN DE BASES DE DATOS DE USUARIOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Este tipo de servicio permite al Titular crear perfiles de usuario a partir de una dirección de correo electrónico, un nombre personal u otra información que el Usuario proporcione a ROSA GAS SOCIEDAD ANONIMA, así como realizar un seguimiento de las actividades del Usuario a través de funciones de análisis. </font>
                     <font style={{ verticalAlign: "inherit" }}>Estos Datos Personales también pueden compararse con información disponible públicamente sobre el Usuario (como perfiles de redes sociales) y utilizarse para crear perfiles privados que el Titular puede mostrar y utilizar para mejorar ROSA GAS SOCIEDAD ANÓNIMA. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Algunos de estos servicios también pueden permitir el envío de mensajes temporizados al Usuario, como correos electrónicos basados ​​en acciones específicas realizadas en ROSA GAS SOCIEDAD ANONIMA.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>HubSpot CRM (HubSpot, Inc.) </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>HubSpot CRM es un servicio de administración de bases de datos de usuarios proporcionado por HubSpot, Inc. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Datos personales procesados: dirección de correo electrónico; </font>
                        <font style={{ verticalAlign: "inherit" }}>número de teléfono; </font>
                        <font style={{ verticalAlign: "inherit" }}>varios tipos de Datos como se especifica en la política de privacidad del servicio. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Lugar de procesamiento: Estados Unidos –&nbsp; </font>
                     </font>
                     <a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noreferrer" data-saferedirecturl="https://www.google.com/url?q=https://legal.hubspot.com/privacy-policy&source=gmail&ust=1686240229980000&usg=AOvVaw0AbiVXr43UP5kEohv1L9G1">
                        <span style={{ color: "rgb(68,138,255)" }}>
                           <font style={{ verticalAlign: "inherit" }}>
                              <font style={{ verticalAlign: "inherit" }}>Política de privacidad</font>
                           </font>
                        </span>
                     </a>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}> .</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INFORMACIÓN SOBRE LA EXCLUSIÓN DE LA PUBLICIDAD BASADA EN INTERESES</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Además de cualquier función de exclusión proporcionada por cualquiera de los servicios enumerados en este documento, los usuarios pueden obtener más información sobre cómo optar por no recibir publicidad basada en intereses en la sección dedicada de la Política de cookies.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>MÁS INFORMACIÓN SOBRE EL TRATAMIENTO DE DATOS PERSONALES</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Notificaciones push</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>ROSA GAS SOCIEDAD ANONIMA podrá enviar notificaciones automáticas al Usuario para lograr los fines descritos en esta política de privacidad.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>En la mayoría de los casos, los usuarios pueden optar por no recibir notificaciones automáticas visitando la configuración de su dispositivo, como la configuración de notificación para teléfonos móviles, y luego cambiar esa configuración para ROSA GAS SOCIEDAD ANONIMA, algunas o todas las aplicaciones en el dispositivo en particular. </font>
                     </font>
                     <br />
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>Los usuarios deben ser conscientes de que la desactivación de las notificaciones push puede afectar negativamente a la utilidad de ROSA GAS SOCIEDAD ANONIMA.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>LOS DERECHOS DE LOS USUARIOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios pueden ejercer determinados derechos respecto de sus Datos tratados por el Titular.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios que se acojan a estándares de protección más amplios podrán ejercer cualquiera de los derechos descritos a continuación. </font>
                     <font style={{ verticalAlign: "inherit" }}>En todos los demás casos, los Usuarios podrán consultar con el Titular para saber qué derechos les corresponden.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>En particular, los Usuarios tienen derecho a lo siguiente:</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Retirar su consentimiento en cualquier momento. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho a retirar el consentimiento cuando hayan dado previamente su consentimiento para el procesamiento de sus Datos personales.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Oponerse al tratamiento de sus Datos. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho a oponerse al procesamiento de sus Datos si el procesamiento se lleva a cabo sobre una base legal distinta del consentimiento. </font>
                        <font style={{ verticalAlign: "inherit" }}>Se proporcionan más detalles en la sección dedicada a continuación.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Acceder a sus Datos. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho a saber si el Titular está procesando los Datos, obtener información sobre ciertos aspectos del procesamiento y obtener una copia de los Datos que se están procesando.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Verificar y solicitar la rectificación. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho a verificar la exactitud de sus Datos y solicitar su actualización o corrección.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Restringir el tratamiento de sus Datos. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho, en determinadas circunstancias, a restringir el tratamiento de sus Datos. </font>
                        <font style={{ verticalAlign: "inherit" }}>En este caso, el Titular no tratará sus Datos con otra finalidad que la de almacenarlos.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Hacer que se eliminen o eliminen sus Datos personales. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho, en determinadas circunstancias, a obtener del Titular la supresión de sus Datos.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Recibir sus Datos y transferirlos a otro controlador. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho a recibir sus Datos en un formato estructurado, de uso común y lectura mecánica y, si es técnicamente posible, a que se transmitan a otro responsable del tratamiento sin ningún tipo de impedimento. </font>
                        <font style={{ verticalAlign: "inherit" }}>Esta disposición es aplicable siempre que los Datos sean tratados por medios automatizados y que el tratamiento se base en el consentimiento del Usuario, en un contrato del que el Usuario sea parte o en obligaciones precontractuales del mismo.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <b>
                     <span style={{ fontSize: "10.5pt" }}>
                        <font style={{ verticalAlign: "inherit" }}>
                           <font style={{ verticalAlign: "inherit" }}>Presenta una queja. </font>
                        </font>
                     </span>
                  </b>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>&nbsp;Los usuarios tienen derecho a presentar una reclamación ante la autoridad de protección de datos competente.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>DETALLES SOBRE EL DERECHO A OPONERSE AL TRATAMIENTO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Cuando los Datos Personales se procesen por un interés público, en el ejercicio de una autoridad oficial conferida al Titular o para los fines de los intereses legítimos perseguidos por el Titular, los Usuarios pueden oponerse a dicho procesamiento proporcionando un motivo relacionado con su situación particular para justificar la objeción.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los usuarios deben saber que, sin embargo, en caso de que sus Datos personales se procesen con fines de marketing directo, pueden oponerse a ese procesamiento en cualquier momento sin proporcionar ninguna justificación. </font>
                     <font style={{ verticalAlign: "inherit" }}>Para saber si el Propietario está procesando Datos personales con fines de marketing directo, los Usuarios pueden consultar las secciones correspondientes de este documento.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>CÓMO EJERCER ESTOS DERECHOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Cualquier solicitud para ejercer los derechos del Usuario puede dirigirse al Titular a través de los datos de contacto proporcionados en este documento. </font>
                     <font style={{ verticalAlign: "inherit" }}>Estas solicitudes podrán ser ejercitadas de forma gratuita y serán atendidas por el Titular con la mayor antelación posible y siempre en el plazo de un mes.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>APLICABILIDAD DE ESTÁNDARES DE PROTECCIÓN MÁS AMPLIOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Si bien la mayoría de las disposiciones de este documento se refieren a todos los Usuarios, algunas disposiciones solo se aplican expresamente si el procesamiento de Datos personales está sujeto a estándares de protección más amplios.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Estos estándares de protección más amplios se aplican cuando el procesamiento:</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <ul type="disc">
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>es realizado por un Propietario con sede en la UE;</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>se refiere a los Datos Personales de los Usuarios que se encuentran en la UE y está relacionado con la oferta de bienes o servicios pagados o no pagados, a dichos Usuarios;</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
               <li className="MsoNormal" style={{ margin: "0in", lineHeight: "18pt", fontSize: "11pt", color: "rgb(39,50,64)", background: "white" }}>
                  <span style={{ fontSize: "10.5pt" }}>
                     <font style={{ verticalAlign: "inherit" }}>
                        <font style={{ verticalAlign: "inherit" }}>se refiere a los Datos personales de los Usuarios que se encuentran en la UE y permite al Propietario monitorear el comportamiento de dichos Usuarios que tienen lugar en la UE.</font>
                     </font>
                     <u />
                     &nbsp;
                     <u />
                  </span>
               </li>
            </ul>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "30pt", fontSize: "11pt", margin: "7.5pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "15pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INFORMACIÓN ADICIONAL SOBRE LA RECOGIDA Y EL PROCESAMIENTO DE DATOS</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>ACCION LEGAL</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Los Datos Personales del Usuario podrán ser utilizados para fines legales por el Titular en sede judicial o en las etapas conducentes a posibles acciones legales derivadas del uso indebido de ROSA GAS SOCIEDAD ANONIMA o de los Servicios relacionados. </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>El Usuario declara ser consciente de que el Titular puede estar obligado a revelar datos personales a petición de las autoridades públicas.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INFORMACIÓN ADICIONAL SOBRE DATOS PERSONALES DEL USUARIO</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Además de la información contenida en esta política de privacidad, ROSA GAS SOCIEDAD ANÓNIMA puede proporcionar al Usuario información adicional y contextual sobre Servicios particulares o la recopilación y procesamiento de Datos Personales previa solicitud.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>REGISTROS Y MANTENIMIENTO DEL SISTEMA</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Para fines de operación y mantenimiento, ROSA GAS SOCIEDAD ANONIMA y cualquier servicio de terceros pueden recopilar archivos que registran la interacción con ROSA GAS SOCIEDAD ANONIMA (Registros del sistema) utilizar otros Datos Personales (como la Dirección IP) para este propósito.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>INFORMACIÓN NO CONTENIDA EN ESTA POLÍTICA</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Se pueden solicitar más detalles sobre la recopilación o el procesamiento de Datos personales al Titular en cualquier momento. </font>
                     <font style={{ verticalAlign: "inherit" }}>Consulte la información de contacto al principio de este documento.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>CÓMO SE MANEJAN LAS SOLICITUDES DE "NO SEGUIMIENTO"</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>ROSA GAS SOCIEDAD ANÓNIMA no admite solicitudes de "No rastrear". </font>
                  </font>
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Para determinar si alguno de los servicios de terceros que utiliza cumple con las solicitudes de "No rastrear", lea sus políticas de privacidad.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "24pt", fontSize: "11pt", margin: "15pt 0in 0in", background: "white" }}>
               <span style={{ fontSize: "13.5pt", color: "rgb(39,50,64)", textTransform: "uppercase" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ lineHeight: "18pt", fontSize: "11pt", margin: "7.5pt 0in", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>El Propietario se reserva el derecho de realizar cambios a esta política de privacidad en cualquier momento notificando a sus Usuarios en esta página y posiblemente dentro de ROSA GAS SOCIEDAD ANÓNIMA y/o, en la medida en que sea técnica y legalmente factible, enviando un aviso a los Usuarios a través de cualquier información de contacto. a disposición del Titular. </font>
                     <font style={{ verticalAlign: "inherit" }}>Se recomienda encarecidamente consultar esta página con frecuencia, consultando la fecha de la última modificación que figura en la parte inferior. </font>
                  </font>
                  <br />
                  <br />
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>En caso de que los cambios afecten a las actividades de procesamiento realizadas sobre la base del consentimiento del Usuario, el Titular deberá recabar el nuevo consentimiento del Usuario, cuando sea necesario.</font>
                  </font>
                  <u />
                  &nbsp;
                  <u />
               </span>
            </p>
            <p className="MsoNormal" style={{ margin: "7.5pt 0in 8pt", lineHeight: "18pt", fontSize: "11pt", background: "white" }}>
               <span style={{ fontSize: "10.5pt", color: "rgb(39,50,64)" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                     <font style={{ verticalAlign: "inherit" }}>Última actualización: Enero 03, 2023</font>
                  </font>
                  <u />
                  &nbsp;
               </span>
            </p>
         </div>
      </Container>
   );
};

export default PrivacyPage;
