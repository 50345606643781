import BaseService from "./BaseService";
import axios from "axios";

export async function GetDeliveryPartnerByUser(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`deliverypartner/byuser/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function CreateDeliveryPartner(entity) {
   return new Promise((resolve, reject) => {
      axios
         .post("deliverypartner", JSON.stringify(entity), {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export default class DeliveryPartnerService extends BaseService {
   static async getAll() {
      try {
         const baseService = new BaseService();
         return await baseService.get("deliverypartner");
      } catch (error) {
         return null;
      }
   }

   static async getById(id) {
      try {
         const baseService = new BaseService();
         return await baseService.get(`deliverypartner/${id}`);
      } catch (error) {
         return null;
      }
   }
}
