import React, { useState, useContext, useEffect } from "react";
import { Avatar, Button, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, Paper } from "@mui/material/";
import { AccountCircle } from "@mui/icons-material/";
import { MyContext } from "../context/GlobalContextProvider";

const handleSubmit = (event) => {
   event.preventDefault();
   const data = new FormData(event.currentTarget);
   console.log({
      email: data.get("email"),
      password: data.get("password"),
   });
};

export const AccountPage = () => {
   const { states, updateStates, resetStates } = useContext(MyContext);
   const { user, customer, deliveryPartner } = states;

   const [name, setName] = useState(customer ? customer.firstName : deliveryPartner ? deliveryPartner.firstName : "");
   const [lastName, setLastName] = useState(customer ? customer.lastName : deliveryPartner ? deliveryPartner.lastName : "");
   const [identification, setIdentification] = useState(customer ? customer.identification : deliveryPartner ? deliveryPartner.identification : "");

   const [email, setEmail] = useState(user ? user.username : "");

   const handleNameChange = (event) => {
      setName(event.target.value);
   };

   const handleLastNameChange = (event) => {
      setLastName(event.target.value);
   };

   const handleIdentificationChange = (event) => {
      setIdentification(event.target.value);
   };

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   useEffect(() => {
      console.log("user: ", user);
      console.log("customer: ", customer);
      console.log("DeliveryPartner: ", deliveryPartner);
   }, []);

   return (
      <>
         <Container component="main" maxWidth="xl" className="mainContainerMinHeight">
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
               }}
            >
               <Avatar sx={{ m: 1, bgcolor: "primary.main", width: 70, height: 70 }}>
                  <AccountCircle sx={{ m: 1, width: 70, height: 70 }} />
               </Avatar>
               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <TextField id="firstName" label="Nombre" value={name} onChange={handleNameChange} required fullWidth disabled />
                     </Grid>

                     <Grid item xs={12} sm={6}>
                        <TextField id="secondName" label="Apellidos" value={lastName} onChange={handleLastNameChange} required fullWidth disabled />
                     </Grid>

                     <Grid item xs={12}>
                        <TextField id="email" label="Email" type="email" defaultValue={email} onChange={handleEmailChange} required fullWidth disabled />
                     </Grid>

                     <Grid item xs={12}>
                        <TextField id="identification" label="Identificación" type="number" defaultValue={identification} onChange={handleIdentificationChange} required fullWidth disabled />
                     </Grid>

                     {customer ? (
                        <Grid item xs={12}>
                           <TextField id="phoneNumber" label="Número de teléfono" type="number" defaultValue={customer.phoneNumber} required fullWidth disabled />
                        </Grid>
                     ) : deliveryPartner ? (
                        <Grid item xs={12}>
                           <TextField id="licensePlate" label="Placa" defaultValue={deliveryPartner.licensePlate} required fullWidth disabled />
                        </Grid>
                     ) : null}
                  </Grid>
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled>
                     Actualizar
                  </Button>
               </Box>
            </Box>
         </Container>
      </>
   );
};
