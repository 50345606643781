export const OrderTypeValues = Object.freeze({ COMPRA: 1, RECARGA: 2, ARTICULO: 3, MIXTO: 4 });

const statusNames = {
   [OrderTypeValues.COMPRA]: "Compra",
   [OrderTypeValues.RECARGA]: "Recarga",
   [OrderTypeValues.ARTICULO]: "Artículo",
   [OrderTypeValues.MIXTO]: "Mixto",
};

export class OrderType {
   constructor(id, name) {
      this.id = id;
      this.name = name;
   }

   static getSpanishName(status) {
      if (statusNames[status]) {
         return statusNames[status];
      } else {
         /* throw new Error(`Invalid status: ${status}`); */
         console.log(`Invalid status: ${status}`);
      }
   }
}
