import BaseService from "./BaseService";

export default class OrderDeliveryStatusService extends BaseService {
   static async get() {
      try {
         const baseService = new BaseService();
         return await baseService.get("orderdeliverystatus");
      } catch (error) {
         return null;
      }
   }
}
