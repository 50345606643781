import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import OrderDeliveryService from "../services/OrderDeliveryService";
import { Container, FormControl, InputLabel, Select, MenuItem, Box, Typography, Grid, Paper } from "@mui/material";
import { MyContext } from "../context/GlobalContextProvider";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import OrderStats from "../components/OrderStats";

export const OrdersPage = () => {
    const [loading, setLoading] = useState(true);
    const [listOrders, setListOrders] = useState(null);
    const [listOrdersTemp, setListOrdersTemp] = useState(null);
    const [listOrdersDelivery, setListOrdersDelivery] = useState(null);
    const [filterOrderDeliveryStatus, setFilterOrderDeliveryStatus] = useState("");
    const [filterDistributionCenter, setFilterDistributionCenter] = useState("");
    const [searchOrder, setSearchOrder] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [orderStats, setOrderStats] = useState({});
    const timerId = useRef(null);

    const navigate = useNavigate();

    const { states } = useContext(MyContext);
    const { user, orderDeliveryStatus, deliveryPartner, products, distributionCenter } = states;
    const [listOrdersLength, setListOrdersLength] = useState(0);

    function formatearFecha(fecha) {
        return new Date(Date.parse(fecha)).toLocaleString("es-ES", { timeZone: "America/Costa_Rica" });
    }

    function ajustarZonaHoraria(fecha) {
        const fechaUTC = new Date(fecha);
        const offset = fechaUTC.getTimezoneOffset() * 60000; // Convertir offset a milisegundos
        const horaCostaRica = new Date(fechaUTC.getTime() - offset);
        return horaCostaRica;
    }

    async function LoadOrders() {
        if (timerId.current) {
            clearTimeout(timerId.current);
        }

        await axios
            .get("order", { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } })
            .then((response) => {
                setListOrders(response.data);
            })
            .catch((error) => {
                /*console.error(error);*/
            });

        timerId.current = setTimeout(LoadOrders, 60000);
    }

    async function LoadOrderDelivery(id) {
        try {
            const response = await axios.get(`orderdelivery/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
                },
            });
            return response.data;
        } catch (error) {
            /*console.error(error);*/
            return null;
        }
    }

    async function LoadOrdersDelivery() {
        try {
            if (listOrders !== null) {

                var tempOrdersDelivery;
                try {
                    const response = await axios.get(`orderdelivery/`, {
                        headers: {
                            "Content-Type": "application/json",
                            REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
                        },
                    });
                    tempOrdersDelivery = response.data;
                } catch (error) {
                    // console.log(error);
                }

                const newOrdersDelivery = await Promise.all(
                    listOrders.map(async (order) => {
                        if (order.orderDeliveryStatusId != 0) {
                            var orderTempDelivery = tempOrdersDelivery.find(od => od.orderId === order.id);
                            orderTempDelivery.dateCreated = ajustarZonaHoraria(orderTempDelivery.dateCreated);
                            orderTempDelivery.dateUpdated = ajustarZonaHoraria(orderTempDelivery.dateUpdated);
                            orderTempDelivery.deliveryDate = ajustarZonaHoraria(orderTempDelivery.deliveryDate);
                            return orderTempDelivery;
                        }

                        return null;
                    })
                );

                const filteredOrdersDelivery = newOrdersDelivery.filter((order) => order !== null);

                const statusCount = filteredOrdersDelivery.reduce((acc, orderDelivery) => {
                    const correspondingOrder = listOrders.find(order => order.id === orderDelivery.orderId);
                    const statusId = correspondingOrder.orderDeliveryStatusId;
                    const statusName = orderDeliveryStatus.find(status => status.id === statusId)?.name || "Cancelado";

                    if (!acc[statusId]) {
                        acc[statusId] = { name: statusName, count: 0 };
                    }
                    acc[statusId].count += 1;

                    return acc;
                }, {});

                // Convertir el objeto en una lista de [key, value] pares, ordenarla, y convertirla de nuevo en un objeto
                const sortedStatusCount = Object.keys(statusCount)
                    .sort((a, b) => parseInt(a) - parseInt(b))
                    .reduce((acc, key) => {
                        acc[key] = statusCount[key];
                        return acc;
                    }, {}); 
                setOrderStats(sortedStatusCount);

                setListOrdersDelivery(filteredOrdersDelivery);

                const ordersWithStatus3 = listOrders.filter(order => order.orderDeliveryStatusId === 3);
                if (ordersWithStatus3.length === 1) {
                    Notification.requestPermission().then((permission) => {
                        if (permission === "granted") {
                            new Notification(`Hay ${ordersWithStatus3.length} órden esperando`);
                        } else {
                            alert("Permiso para notificaciones denegado.");
                        }
                    });
                } else if (ordersWithStatus3.length > 1) {
                    Notification.requestPermission().then((permission) => {
                        if (permission === "granted") {
                            new Notification(`Hay ${ordersWithStatus3.length} órdenes esperando`);
                        } else {
                            alert("Permiso para notificaciones denegado.");
                        }
                    });
                }
            }
        } catch (error) {
            /* console.log(error); */
        } finally {
            setLoading(false);
        }
    }

    const searchState = async (e) => {
        setSearchOrder(e.target.value);
    };

    function matchesSearch(order, orderDelivery, searchOrder) {
        const lowerCaseSearchOrder = searchOrder.toLowerCase();

        const statusName = orderDeliveryStatus.find((status) => status.id === order.orderDeliveryStatusId)?.name || "Cancelado";
        const centerName = distributionCenter.find((center) => center.id === orderDelivery.distributionCenterId)?.name || "No encontrado";

        // Convertimos todo a minúsculas antes de hacer la comparación
        return order.id.toString().toLowerCase().includes(lowerCaseSearchOrder) || orderDelivery.dateCreated.toLocaleString("es-ES", { timeZone: "America/Costa_Rica" }).toString().toLowerCase().includes(lowerCaseSearchOrder) || statusName.toLowerCase().includes(lowerCaseSearchOrder) || centerName.toLowerCase().includes(lowerCaseSearchOrder);
    }

    function calculateDuration(dateCreated, deliveryDate) {
        const startDate = new Date(dateCreated);
        const endDate = deliveryDate ? new Date(deliveryDate) : new Date(ajustarZonaHoraria(new Date.UTC()));
        const durationInMilliseconds = endDate - startDate;

        const secondsTotal = Math.floor(durationInMilliseconds / 1000);
        const minutesTotal = Math.floor(secondsTotal / 60);
        const hoursTotal = Math.floor(minutesTotal / 60);
        const days = Math.floor(hoursTotal / 24);

        const seconds = secondsTotal % 60;
        const minutes = minutesTotal % 60;
        const hours = hoursTotal % 24;

        if (days > 0) {
            return `${days}d:${hours}h:${minutes}m`;
        } else if (hours > 0) {
            return `${hours}h:${minutes}m:${seconds}s`;
        } else if (minutes > 0) {
            return `${minutes}m:${seconds}s`;
        } else {
            return `${seconds}s`;
        }
    }

    useEffect(() => {
        LoadOrders();

        return () => {
            clearTimeout(timerId.current);
        };
    }, []);

    useEffect(() => {
        if (listOrders != null) {
            if (listOrdersLength != listOrders.length) {
                setListOrdersLength(listOrders.length);
                setListOrdersTemp(listOrders);
                LoadOrdersDelivery(); 
                enqueueSnackbar("Nuevos datos cargados", { variant: "success" });
            } else if (listOrdersTemp != null && JSON.stringify(listOrders) !== JSON.stringify(listOrdersTemp)) {
                LoadOrdersDelivery();
            }


        }
    }, [listOrders]);

    return (
        <Container maxWidth="xl">
            <Typography component="h2" align="center" variant="h4" m={1}>
                Pedidos
            </Typography>
            {/* sx={{ width: { xs: "100%", md: "25%" } }} */}
            <FormControl role="search" sx={{ marginBottom: 2, display: "flex", flexDirection: { xs: "column", md: "row" } }}>
                <input
                    className="form-control"
                    type="search"
                    placeholder="Buscar..."
                    aria-label="Search"
                    onChange={(e) => {
                        searchState(e);
                    }}
                />

                <FormControl size="small" sx={{ marginLeft: { xs: 0, md: 1 }, marginRight: { xs: 0, md: 1 }, width: { xs: "100%", md: "25%" } }}>
                    <InputLabel id="select-orderdeliverystatus">Estado</InputLabel>
                    <Select
                        labelId="select-orderdeliverystatus"
                        id="selectorderdeliverystatus"
                        value={filterOrderDeliveryStatus}
                        onChange={(e) => {
                            setFilterOrderDeliveryStatus(e.target.value);
                        }}
                        label="Estado"
                    >
                        <MenuItem value="">Todos</MenuItem>
                        {orderDeliveryStatus.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl size="small" sx={{ width: { xs: "100%", md: "25%" } }}>
                    <InputLabel id="select-distributioncenter">Sucursal</InputLabel>
                    <Select
                        labelId="select-distributioncenter"
                        id="selectdistributioncenter"
                        value={filterDistributionCenter}
                        onChange={(e) => {
                            setFilterDistributionCenter(e.target.value);
                        }}
                        label="Sucursal"
                    >
                        <MenuItem value="">Todos</MenuItem>
                        {distributionCenter.map((x) => (
                            <MenuItem value={x.id} key={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormControl>

            {listOrdersDelivery === null ? (
                loading ? (
                    <Loading />
                ) : (
                    <p>Cargando...</p>
                )
            ) : (
                <>
                    <Box >
                        <Grid container spacing={2}>
                            {Object.keys(orderStats).map((statusId, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Paper elevation={1} sx={{ p: 1, textAlign: 'center' }}>
                                        <Typography variant="h6">
                                            {orderStats[statusId].name}
                                        </Typography>
                                        <Typography variant="body1">
                                            {orderStats[statusId].count} pedidos
                                        </Typography>
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>

                        <br />
                    </Box>

                    <div className="table-responsive">


                        <table className="table text-center">
                            <caption>RosaGas</caption>
                            <thead>
                                <tr>
                                    <th scope="col">Referencia</th>
                                    <th scope="col">Fecha</th>
                                    {/* <th scope="col">Duración</th> */}
                                    <th scope="col">Estado</th>
                                    <th scope="col">Sucursal</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listOrdersDelivery.map((orderDelivery) => {
                                    const correspondingOrder = listOrders.find((order) => order.id === orderDelivery.orderId);
                                    const matchesStatusFilter = filterOrderDeliveryStatus === "" || correspondingOrder.orderDeliveryStatusId === filterOrderDeliveryStatus;
                                    const matchesDistributionCenterFilter = filterDistributionCenter === "" || orderDelivery.distributionCenterId === filterDistributionCenter;

                                    if (matchesStatusFilter && matchesDistributionCenterFilter && (!searchOrder || matchesSearch(correspondingOrder, orderDelivery, searchOrder))) {
                                        return (
                                            <tr key={correspondingOrder.id}>
                                                <th scope="row" className="align-middle">
                                                    {correspondingOrder.id}
                                                </th>
                                                <td className="align-middle">{orderDelivery.dateCreated.toLocaleString("es-ES", { timeZone: "America/Costa_Rica" })}</td>
                                                <td className="align-middle">{orderDeliveryStatus ? orderDeliveryStatus.find((status) => status.id === correspondingOrder.orderDeliveryStatusId)?.name || "Cancelado" : "cargando"}</td>
                                                <td className="align-middle">{distributionCenter ? distributionCenter.find((status) => status.id === orderDelivery.distributionCenterId)?.name || "No encontrado" : "cargando"}</td>
                                                <td className="align-middle">
                                                    <button
                                                        className="btn btn-sm btn-outline-primary w-75"
                                                        onClick={() => {
                                                            navigate(`/order/${correspondingOrder.id}`);
                                                        }}
                                                    >
                                                        Ver
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </Container>
    );
};
