import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { Container, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Grid } from "@mui/material";
import { Search as SearchIcon, AddRounded, EditRounded } from "@mui/icons-material";
import { TransactionStatus, TransactionStatusValues } from "../models/TransactionStatus";
import { GetTransactionAll } from "../services/TransactionService";
import { MyContext } from "../context/GlobalContextProvider";

export const TransactionsPage = () => {
   const [listTransactions, setListTransactions] = useState(null);
   const [filterTransactionStatus, setFilterTransactionStatus] = useState("");
   const [loading, setLoading] = useState(true);
   const [search, setSearch] = useState("");

   const { states } = useContext(MyContext);
   const { user } = states;

   const timerId = useRef(null);

   const navigate = useNavigate();

   async function LoadTransactions() {
      if (timerId.current) {
         clearTimeout(timerId.current);
      }

      const response = await GetTransactionAll();
      if (response.Result !== null) setListTransactions(response.Result);

      timerId.current = setTimeout(LoadTransactions, 10000);
   }

   const handleSearchChange = (event) => {
      event.preventDefault();
      setSearch(event.target.value);
   };

   useEffect(() => {
      if (user.profileId === 1) {
         LoadTransactions();
         setLoading(false);

         return () => {
            clearTimeout(timerId.current);
         };
      } else {
         navigate("/");
      }
   }, []);

   return (
      <Container maxWidth="xl" className="mainContainerMinHeight">
         <Typography component="h2" align="center" variant="h4" margin={1}>
            Transacciones
         </Typography>

         <Grid container spacing={2}>
            <Grid item xs={9}>
               <FormControl fullWidth variant="outlined" size="small" sx={{ marginTop: 1, marginBottom: 1 }}>
                  <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
                  <OutlinedInput
                     id="outlined-adornment-search"
                     onChange={handleSearchChange}
                     endAdornment={
                        <InputAdornment position="end">
                           <IconButton edge="end">
                              <SearchIcon />
                           </IconButton>
                        </InputAdornment>
                     }
                     label="Buscar"
                  />
               </FormControl>
            </Grid>

            <Grid item xs={3}>
               <FormControl fullWidth size="small" sx={{ marginTop: 1, marginBottom: 1 }}>
                  <InputLabel id="select-transaction-state">Filtro</InputLabel>
                  <Select
                     labelId="select-transaction-state"
                     id="select-transactionstate"
                     value={filterTransactionStatus}
                     onChange={(e) => {
                        setFilterTransactionStatus(e.target.value);
                     }}
                     label="Estado"
                  >
                     <MenuItem value="">Todos</MenuItem>
                     {Object.values(TransactionStatusValues).map((status) => {
                        const transactionStatus = new TransactionStatus(status, TransactionStatus.getSpanishName(status));
                        return (
                           <MenuItem value={transactionStatus.id} key={transactionStatus.id}>
                              {transactionStatus.name}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
            </Grid>
         </Grid>

         <IconButton
            title="Crear Usuario"
            color="primary"
            sx={{ marginBottom: 1 }}
            onClick={() => {
               navigate("/transaction/0");
            }}
         >
            <AddRounded />
         </IconButton>

         {listTransactions === null ? (
            loading ? (
               <Loading />
            ) : (
               <p>No hay transacciones disponibles</p>
            )
         ) : (
            <>
               <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table" stickyHeader>
                     <TableHead>
                        <TableRow>
                           <TableCell>Id</TableCell>
                           <TableCell>Estado</TableCell>
                           <TableCell>Fecha</TableCell>
                           <TableCell>Monto</TableCell>
                           <TableCell>Transacción</TableCell>
                           <TableCell align="center">Opcción</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {listTransactions.map((object) => {
                           if ((search === "" || object.id.toString().includes(search) || object.transactionDate.includes(search) || object.amount.toString().includes(search) || object.nameOnTransaction.toLowerCase().includes(search)) && (filterTransactionStatus === "" || object.transactionStatusId === filterTransactionStatus)) {
                              return (
                                 <TableRow key={object.id}>
                                    <TableCell>{object.id}</TableCell>
                                    <TableCell>{TransactionStatus.getSpanishName(object.transactionStatusId)}</TableCell>
                                    <TableCell component="th" scope="row">
                                       {new Date(object.transactionDate).toLocaleString("es-CR")}
                                    </TableCell>
                                    <TableCell>{object.amount}</TableCell>
                                    <TableCell>{object.nameOnTransaction}</TableCell>
                                    <TableCell align="center">
                                       <IconButton
                                          title="Editar"
                                          color="primary"
                                          onClick={() => {
                                             navigate(`/transaction/${object.id}`);
                                          }}
                                       >
                                          <EditRounded />
                                       </IconButton>
                                    </TableCell>
                                 </TableRow>
                              );
                           } else {
                              return null;
                           }
                        })}
                     </TableBody>
                  </Table>
               </TableContainer>
            </>
         )}
      </Container>
   );
};

export default TransactionsPage;
