import axios from "axios";

export async function GetTransactionAll() {
   return new Promise((resolve, reject) => {
      axios
         .get(`transaction`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function GetTransaction(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`transaction/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function UpdateTransaction(entity) {
   return new Promise((resolve, reject) => {
      axios
         .put("transaction", entity, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function CreateTransaction(entity) {
   return new Promise((resolve, reject) => {
      axios
         .post("transaction", entity, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}