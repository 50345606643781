import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Typography, Grid, TextField, Button, FormControl, Select, InputLabel, MenuItem, Paper, Divider, Card, Box } from "@mui/material";
import { Notification } from "../models/Notification";
import { GetNotificationTypes } from "../services/NotificationTypeService";
import { CreateNotification, GetNotifications } from "../services/NotificationService";
import Loading from "../components/Loading";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";
import { AlertDialogContext } from "../components/AlertDialogSlide/AlertDialogContext";

export const NotificationsPage = () => {
    const navigate = useNavigate();
    const snackbar = useContext(SnackbarContext);
    const { openAlertDialog } = useContext(AlertDialogContext);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [user, setUser] = useState(undefined);
    const [notification, setNotification] = useState(new Notification());
    const [notifications, setNotifications] = useState(undefined);
    const [arrayNotificationType, setArrayNotificationType] = useState(undefined);
    /* Handles */
    function handleVerification() {
        return new Promise((resolve, reject) => {
            if (notification.notificationTypeId !== 0 && notification.text !== null && notification.text !== "") resolve(true);
            else resolve(false);
        });
    }

    async function HandleSubmit() {
        setLoadingButton(true);

        try {
            const valid = await handleVerification();
            if (valid) {
                const response = await CreateNotification(notification);
                if (response !== undefined && response.Success == true) {
                    setNotification(new Notification());
                    snackbar.current.enqueueSnackbar(`Notificación creada exitosamente`, {
                        variant: "success",
                    });
                    LoadNotifications();
                } else {
                    snackbar.current.enqueueSnackbar(response.Message, {
                        variant: "error",
                    });
                }

            }

        } catch (error) {
            snackbar.current.enqueueSnackbar(`Error al crear Notificación`, {
                variant: "error",
            })
        } finally {
            setLoadingButton(false);
        }
    }

    async function LoadNotifications() {
        try {
            const notificationsResponse = await GetNotifications();
            if (notificationsResponse !== null) {
                setNotifications(notificationsResponse);
            }
        } catch (error) {
            snackbar.current.enqueueSnackbar(error, {
                variant: "error",
            });
        }
    }

    async function LoadNotificationType() {
        try {
            const notificationTypeResponse = await GetNotificationTypes();
            if (notificationTypeResponse !== null) {
                setArrayNotificationType(notificationTypeResponse);
            }
        } catch (error) {
            snackbar.current.enqueueSnackbar(error, {
                variant: "error",
            });
        }
    }

    async function LoadObjects() {
        try {
            await LoadNotifications();
            await LoadNotificationType();
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    // useEffect(() => { console.log(user) }, [user])

    useEffect(() => {
        LoadObjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container maxWidth="xl" className="mainContainerMinHeight">
            <Typography component="h2" align="center" variant="h4" mb={2}>
                Crear Notificación
            </Typography>

            {loading ? (
                <Loading />
            ) : arrayNotificationType !== null ? (<>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="notificationId">Tipo de notificación</InputLabel>
                            <Select
                                labelId="notificationId"
                                id="notification"
                                label="Tipo de notificación"
                                value={notification.notificationTypeId}
                                onChange={(e) => {
                                    setNotification(prevState => ({
                                        ...prevState,
                                        notificationTypeId: e.target.value
                                    }));
                                }}
                            >
                                {arrayNotificationType.map((x) => (
                                    <MenuItem value={x.id} key={x.id}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            id="notificationBody"
                            label="Cuerpo de la notificación"
                            multiline
                            rows={4}
                            fullWidth
                            value={notification.text}
                            onChange={(e) => {
                                setNotification(prevState => ({
                                    ...prevState,
                                    text: e.target.value
                                }));
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {/*<Grid item xs={12} sm={6}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    fullWidth
                                    onClick={() => {
                                        navigate("../distribution-centers");
                                    }}
                                >
                                    Atras
                                </Button>
                            </Grid>*/}

                            <Grid item xs={12}  >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {
                                        HandleSubmit();
                                    }}
                                >
                                    Crear
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        {notifications !== null && notifications !== undefined ?
                            <>
                                <Typography component="h2" align="center" variant="h4" mb={2}>
                                    Notificaciones
                                </Typography>
                                {
                                    notifications.map((x) => (
                                        <Box mt={1} key={x.id}>
                                            <Card>
                                                <Box p={1}>
                                                    <Typography >{x.dateCreated}</Typography>
                                                    <Typography >{x.text}</Typography>
                                                </Box>
                                            </Card>
                                        </Box>
                                    ))
                                }
                            </> : null}
                    </Grid>
                </Grid>
            </>) : null
            }
        </Container >
    );
};
