const Loading = () => (
  <div className="fixed-top">
    {/* style={{backgroundColor:"rgba(0,0,0,0.1)"}} */} 
    <div className="m-0 vh-100 row justify-content-center align-items-center" style={{backgroundColor:"rgba(255,255,255,0.5)"}}>
      <div className="col-auto">
        <div className="spinner-border" role="status" style={{ width: "5rem", height: "5rem", color: "#ed0a8f" }}></div>
      </div>
    </div>
  </div>
);
export default Loading;
