import { HomePage } from "./views/HomePage";
import { DistributionCentersPage } from "./views/DistributionCentersPage";
import { DistributionCenterPage } from "./views/DistributionCenterPage";
import { ProductsPage } from "./views/ProductsPage";
import { LoginPage } from "./views/LoginPage";
import { UsersPage } from "./views/UsersPage";
import { TransactionPage } from "./views/TransactionPage";
import { TransactionsPage } from "./views/TransactionsPage";
import { OrdersPage } from "./views/OrdersPage";
import { OrderDetailPage } from "./views/OrderDetailPage";
import PrivacyPage from "./views/PrivacyPage";
import LegalPage from "./views/LegalPage";
import SupportPage from "./views/SupportPage";
import TermsOfUsePage from "./views/TermsOfUsePage";
import { AccountPage } from "./views/AccountPage";
import { UserPage } from "./views/UserPage";
import { ApplicationManagerPage } from "./views/ApplicationManagerPage";
import { NotificationsPage } from "./views/NotificationsPage";

const AppRoutes = [
    {
        index: true,
        element: <HomePage />,
    },
    {
        path: "/home",
        element: <HomePage />,
    },
    ,
    {
        path: "/orders",
        element: <OrdersPage />,
    },
    {
        path: "/pedidos",
        element: <OrdersPage />,
    },
    {
        path: "/order/:orderIdParam",
        element: <OrderDetailPage />,
    },
    {
        path: "/transactions",
        element: <TransactionsPage />,
    },
    {
        path: "/transaction/:transactionId",
        element: <TransactionPage />,
    },
    {
        path: "/distribution-center/:distributionCenterId",
        element: <DistributionCenterPage />,
    },
    {
        path: "/distribution-centers",
        element: <DistributionCentersPage />,
    },
    {
        path: "/products",
        element: <ProductsPage />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/users",
        element: <UsersPage />,
    },
    {
        path: "/support",
        element: <SupportPage />,
    },
    {
        path: "/privacy",
        element: <PrivacyPage />,
    },
    ,
    {
        path: "/terms",
        element: <TermsOfUsePage />,
    },
    {
        path: "/legal",
        element: <LegalPage />,
    },
    {
        path: "/account",
        element: <AccountPage />,
    },
    {
        path: "/user/:id",
        element: <UserPage />,
    },
    {
        path: "/application-manager",
        element: <ApplicationManagerPage />,
    },
    {
        path: "/notifications",
        element: <NotificationsPage />,
    },
];

export default AppRoutes;
