import React from "react";
import { Typography, Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Container from "@mui/material/Container";
import MapComponent from "../components/GoogleMaps";
import { BackHand } from "@mui/icons-material";

const SupportPage = () => {

    const containerStyles = {
        /* backgroundColor: 'var(--LightBackgroundColor)' */
    };

    return (
        <Container maxWidth="xl" align="center" className="mainContainerMinHeight" style={containerStyles}>
            <Typography component="h1" variant="h3" gutterBottom>
                Soporte
            </Typography>


            <Typography component="h2" variant="Title">
                Información de contacto
            </Typography>

            <br />

            <Link href="https://wa.me/50670787742" className="text-decoration-none" target="_blank" rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center' }} justifyContent="center">
                <Typography component="span" variant="body1" sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    General: +506 7078 7742
                </Typography>
                <WhatsAppIcon sx={{ color: "#25D366", fontSize: '2rem', marginLeft: '0.5rem' }} />
            </Link>

            <br />

            <Link href="https://wa.me/50683714409" className="text-decoration-none" target="_blank" rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center' }} justifyContent="center">
                <Typography component="span" variant="body1" sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Gerente de ventas: +506 8371 4409
                </Typography>
                <WhatsAppIcon sx={{ color: "#25D366", fontSize: '2rem', marginLeft: '0.5rem' }} />
            </Link>

            <br />

            <Link href="https://wa.me/50672540121" className="text-decoration-none" target="_blank" rel="noreferrer noopener" style={{ display: 'flex', alignItems: 'center' }} justifyContent="center">
                <Typography component="span" variant="body1" sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    Aplicación móvil: +506 7254 0121
                </Typography>
                <WhatsAppIcon sx={{ color: "#25D366", fontSize: '2rem', marginLeft: '0.5rem' }} />
            </Link>

            <br />
            <br />

            <Typography component="h2" variant="Title">
                Pasos para Eliminar tu cuenta
            </Typography>

            <br />

            <ul style={{ listStyle: "none" }}>
                <li>1. Inicia sesión en la aplicación RosaGas.</li>
                <li>2. Ir a la sesión de su cuenta.</li>
                <li>3. Baja hasta el final y presiona el botón que dice: Eliminar cuenta.</li>
                <li>4. Confirma que deseas eliminar tu cuenta.</li>
                <li>5. Eso es todo, tu cuenta se eliminará dentro de los 90 días si no inicias sesión antes.</li>
            </ul>

            {/*<br />*/}

            {/*<Typography component="h2" variant="Title">*/}
            {/*   Áreas de cobertura*/}
            {/*</Typography>*/}

            {/*<br />*/}

            {/*<MapComponent />*/}
        </Container>
    );
};

export default SupportPage;
