export class Transaction {
   constructor({ id = 0, customerId = 0, transactionTypeId = 1, transactionDate = "", document = "", reference = "", description = "", amount = 0, nameOnTransaction = "", proofOfTransactionImage = null, transactionStatusId = 1, dateCreated = "2023-01-01T00:00:00", dateUpdated = "0001-01-01T00:00:00", proofOfTransactionImageBase64 = null } = {}) {
      this.id = id;
      this.customerId = customerId;
      this.transactionTypeId = transactionTypeId;
      this.transactionDate = transactionDate || new Date().toISOString();
      this.document = document;
      this.reference = reference;
      this.description = description;
      this.amount = amount;
      this.nameOnTransaction = nameOnTransaction;
      this.proofOfTransactionImage = proofOfTransactionImage;
      this.transactionStatusId = transactionStatusId;
      this.dateCreated = dateCreated;
      this.dateUpdated = dateUpdated;
      this.proofOfTransactionImageBase64 = proofOfTransactionImageBase64;
   }
}
