import "bootstrap/dist/css/bootstrap.css";
import "./assets/styles/index.css";
import "./assets/styles/animations.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { MyProvider } from "./context/GlobalContextProvider";
import { AlertDialogProvider } from './components/AlertDialogSlide/AlertDialogContext';
import { App } from "./App";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { SnackbarProviderWrapper } from "./components/SnackbarProviderWrapper";

import { CustomGlobalStyles } from "./assets/styles/CustomGlobalStyles";

import { CustomTheme } from "./assets/styles/CustomTheme";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <HashRouter basename={baseUrl}>
        <ThemeProvider theme={CustomTheme}>
            <CustomGlobalStyles />
            <CssBaseline />
            <MyProvider>
                <AlertDialogProvider>
                    <SnackbarProviderWrapper>
                        <App />
                    </SnackbarProviderWrapper>
                </AlertDialogProvider>
            </MyProvider>
        </ThemeProvider>
    </HashRouter>
);
