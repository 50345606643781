import BaseService from "./BaseService";
import axios from "axios";

export default class OrderDeliveryService extends BaseService {
    /* async getAll() {
       const orderDelivery = await this.fetchData("orderDelivery");
       return orderDelivery;
    } */

    /* async getAllIsPickup() {
       const orderDelivery = await this.fetchData("orderDelivery");
       if (orderDelivery != null) {
          const isPickupOrders = orderDelivery.filter((order) => order.IsPickup === 1);
          return isPickupOrders;
       }
    } */

    async getOrderDeliveryByOrderId(orderId) {
        const orderDelivery = await this.fetchData(`orderDelivery/${orderId}`);
        if (orderDelivery != null) {
            const isPickupOrders = orderDelivery.filter((order) => order.IsPickup === 1);
            return isPickupOrders;
        } else {
            return null;
        }
    }

    static async getAll() {
        try {
            const baseService = new BaseService();
            return await baseService.get("orderdelivery");
        } catch (error) {
            return null;
        }
    }

    static async getByOrderId(id) {
        try {
            const baseService = new BaseService();
            return await baseService.get(`orderdelivery/${id}`);
        } catch (error) {
            return null;
        }
    }
}

export async function UpdateOrderDelivery(entity) {
    try {
        const response = await axios.put("orderdelivery", JSON.stringify(entity), {
            headers: {
                "Content-Type": "application/json",
                REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
        });
        return response.data;
    } catch (error) {
        return null;
        /* console.log(error); */
    }
}