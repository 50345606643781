import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { Container, Typography, Grid, TextField, Button, Checkbox, FormControlLabel, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import axios from "axios";
import { DistributionCenter } from "../models/DistributionCenter";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";
import { GetDistributionCenterById } from "../services/DistributionCenterService";
import { GetAllProvinces } from "../services/ProvinceService";
import { GetAllCantonsByProvinceId } from "../services/CantonService";
import { GetAllDistricsByCantonId } from "../services/DistrictService";

export const DistributionCenterPage = () => {
   const navigate = useNavigate();

   const snackbar = useContext(SnackbarContext);

   const { distributionCenterId } = useParams();

   const [loading, setLoading] = useState(true);
   const [distributionCenter, setDistributionCenter] = useState(null);

   const [provinces, setProvinces] = useState(null);
   const [districts, setDistricts] = useState(null);
   const [cantons, setCantons] = useState(null);

   const getDateNow = () => {
      var dateNow = new Date();
      return dateNow.toISOString();
   };

   async function HandleProvince(e) {
      e.preventDefault();
      if (parseInt(e.target.value)) {
         const responseGetAllCantonsByProvinceId = await GetAllCantonsByProvinceId(e.target.value);
         if (responseGetAllCantonsByProvinceId.Result !== null) {
            setCantons(responseGetAllCantonsByProvinceId.Result);
            setDistributionCenter({ ...distributionCenter, provinceId: parseInt(e.target.value), cantonId: "", districtId: "" });
            setDistricts(null);
         } else
            snackbar.current.enqueueSnackbar(`Error al obtener los cantones`, {
               variant: "error",
            });
      }
   }

   async function HandleCanton(e) {
      e.preventDefault();
      if (parseInt(e.target.value)) {
         const responseGetAllDistricsByCantonId = await GetAllDistricsByCantonId(e.target.value);
         if (responseGetAllDistricsByCantonId.Result !== null) {
            setDistricts(responseGetAllDistricsByCantonId.Result);
            setDistributionCenter({ ...distributionCenter, cantonId: parseInt(e.target.value), districtId: "" });
         } else
            snackbar.current.enqueueSnackbar(`Error al obtener los distritos`, {
               variant: "error",
            });
      }
   }

   async function HandleDistrict(e) {
      e.preventDefault();
      if (parseInt(e.target.value)) {
         setDistributionCenter({ ...distributionCenter, districtId: parseInt(e.target.value) });
      }
   }

   function handleVerification() {
      return new Promise((resolve, reject) => {
         if (distributionCenter.name !== "" && distributionCenter.address !== "" && distributionCenter.provinceId !== "" && distributionCenter.cantonId !== "" && distributionCenter.districtId !== "" && distributionCenter.latitude !== 0 && distributionCenter.longitude !== 0 && distributionCenter.openingHours !== "") resolve(true);
         else resolve(false);
      });
   }

   async function HandleSubmit() {
      setLoading(true);
      const valid = await handleVerification();
      if (valid) {
         if (distributionCenterId === "0") {
            try {
               const response = await axios.post("distributioncenter/", distributionCenter, {
                  headers: {
                     "Content-Type": "application/json",
                     REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
                  },
               });

               if (response.status === 200) {
                  snackbar.current.enqueueSnackbar("Centro de Distribución creado con éxito", {
                     variant: "success",
                  });
                  navigate("/distribution-centers");
               }
            } catch (error) {
               snackbar.current.enqueueSnackbar(`Error al crear Centro de Distribución`, {
                  variant: "error",
               });
            } finally {
               setLoading(false);
            }
         } else {
            try {
               distributionCenter.dateUpdated = getDateNow();

               const response = await axios.put("distributioncenter/", distributionCenter, {
                  headers: {
                     "Content-Type": "application/json",
                     REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
                  },
               });

               if (response.status === 200) { 
                  snackbar.current.enqueueSnackbar("Centro de distribución actualizado con éxito", {
                     variant: "success",
                  });
                  navigate("/distribution-centers");
               }
            } catch (error) {
               snackbar.current.enqueueSnackbar(`Error al actualizar Centro de Distribución`, {
                  variant: "error",
               });
            } finally {
               setLoading(false);
            }
         }
      } else {
         setLoading(false);
         snackbar.current.enqueueSnackbar(`Faltan campos obligatorios *`, {
            variant: "error",
         });
      }
   }

   async function LoadProvinces() {
      const responseGetAllProvinces = await GetAllProvinces();
      if (responseGetAllProvinces.Result !== null) {
         setProvinces(responseGetAllProvinces.Result);
      } else {
         snackbar.current.enqueueSnackbar(`Error al obtener las provincias`, {
            variant: "error",
         });
      }
   }

   async function Load() {
      await LoadProvinces();

      if (distributionCenterId === "0") {
         setDistributionCenter(new DistributionCenter());
      } else {
         const responseGetDistributionCenterById = await GetDistributionCenterById(distributionCenterId);
         if (responseGetDistributionCenterById.Result !== null) {
            setDistributionCenter(responseGetDistributionCenterById.Result);

            const responseGetAllCantonsByProvinceId = await GetAllCantonsByProvinceId(responseGetDistributionCenterById.Result.provinceId);
            if (responseGetAllCantonsByProvinceId.Result !== null) {
               setCantons(responseGetAllCantonsByProvinceId.Result);

               const responseGetAllDistricsByCantonId = await GetAllDistricsByCantonId(responseGetDistributionCenterById.Result.cantonId);
               if (responseGetAllDistricsByCantonId.Result !== null) {
                  setDistricts(responseGetAllDistricsByCantonId.Result);
               } else {
                  snackbar.current.enqueueSnackbar(`Error al obtener los distritos`, {
                     variant: "error",
                  });
               }
            } else {
               snackbar.current.enqueueSnackbar(`Error al obtener los cantones`, {
                  variant: "error",
               });
            }
         } else {
            snackbar.current.enqueueSnackbar(`Error al obtener el Centro de Distribución`, {
               variant: "error",
            });
         }
      }
      setLoading(false);
   }

/*    useEffect(() => {
      console.log(distributionCenter);
   }, [distributionCenter]); */

   useEffect(() => {
      Load();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <Container maxWidth="xl" className="mainContainerMinHeight">
         <Typography component="h2" align="center" variant="h4" margin={3}>
            Centro de Distribución
         </Typography>

         {distributionCenter === null || loading ? (
            <Loading />
         ) : (
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <TextField
                     id="nombre"
                     label="Nombre"
                     variant="outlined"
                     defaultValue={distributionCenter.name}
                     required
                     fullWidth
                     onChange={(e) => {
                        setDistributionCenter({ ...distributionCenter, name: e.target.value });
                     }}
                  />
               </Grid>

               <Grid item xs={12} sm={6}>
                  <TextField
                     id="direccion"
                     label="Dirección"
                     variant="outlined"
                     defaultValue={distributionCenter.address}
                     required
                     fullWidth
                     onChange={(e) => {
                        setDistributionCenter({ ...distributionCenter, address: e.target.value });
                     }}
                  />
               </Grid>

               <Grid item xs={12}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                           <InputLabel id="provinceId" required>
                              Provincia
                           </InputLabel>
                           <Select
                              labelId="provinceId"
                              id="province"
                              label="Provincia"
                              value={distributionCenter.provinceId}
                              onChange={(e) => {
                                 HandleProvince(e);
                              }}
                           >
                              {provinces === null ? (
                                 <MenuItem value="" key="0"></MenuItem>
                              ) : (
                                 provinces.map((item) => {
                                    return (
                                       <MenuItem value={item.id} key={item.id}>
                                          {item.name}
                                       </MenuItem>
                                    );
                                 })
                              )}
                           </Select>
                        </FormControl>
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                           <InputLabel id="cantonId" required>
                              Cantón
                           </InputLabel>
                           <Select
                              labelId="cantonId"
                              id="canton"
                              label="Cantón"
                              value={distributionCenter.cantonId}
                              onChange={(e) => {
                                 HandleCanton(e);
                              }}
                           >
                              {cantons === null
                                 ? null
                                 : cantons.map((item) => {
                                      return (
                                         <MenuItem value={item.id} key={item.id}>
                                            {item.name}
                                         </MenuItem>
                                      );
                                   })}
                           </Select>
                        </FormControl>
                     </Grid>

                     <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                           <InputLabel id="districtId" required>
                              Distrito
                           </InputLabel>
                           <Select
                              labelId="districtId"
                              id="district"
                              label="Distrito"
                              value={distributionCenter.districtId}
                              onChange={(e) => {
                                 HandleDistrict(e);
                              }}
                           >
                              {districts === null
                                 ? null
                                 : districts.map((item) => {
                                      return (
                                         <MenuItem value={item.id} key={item.id}>
                                            {item.name}
                                         </MenuItem>
                                      );
                                   })}
                           </Select>
                        </FormControl>
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           id="latitude"
                           label="Latitud"
                           variant="outlined"
                           type="number"
                           required
                           fullWidth
                           defaultValue={distributionCenter.latitude}
                           onChange={(e) => {
                              setDistributionCenter({ ...distributionCenter, latitude: e.target.value });
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           id="longitude"
                           label="Longitud"
                           variant="outlined"
                           type="number"
                           required
                           fullWidth
                           defaultValue={distributionCenter.longitude}
                           onChange={(e) => {
                              setDistributionCenter({ ...distributionCenter, longitude: e.target.value });
                           }}
                        />
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           id="phoneId"
                           label="Teléfono"
                           variant="outlined"
                           type="number"
                           required
                           fullWidth
                           defaultValue={distributionCenter.phoneNumber}
                           onChange={(e) => {
                              setDistributionCenter({ ...distributionCenter, phoneNumber: e.target.value });
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField
                           id="openingHours"
                           label="Horas abiertas"
                           variant="outlined"
                           required
                           fullWidth
                           defaultValue={distributionCenter.openingHours}
                           onChange={(e) => {
                              setDistributionCenter({ ...distributionCenter, openingHours: e.target.value });
                           }}
                        />
                     </Grid>
                  </Grid>
               </Grid>

               <Grid item xs={12} sm={6}>
                  <FormControlLabel control={<Checkbox defaultChecked={distributionCenter.active} />} label="Activo" disabled />
               </Grid>

               <Grid item xs={12}>
                  <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <Button
                           variant="outlined"
                           color="error"
                           fullWidth
                           onClick={() => {
                              navigate("../distribution-centers");
                           }}
                        >
                           Atras
                        </Button>
                     </Grid>

                     <Grid item xs={12} sm={6}>
                        <Button
                           variant="contained"
                           color="primary"
                           fullWidth
                           onClick={() => {
                              HandleSubmit();
                           }}
                        >
                           {distributionCenterId === "0" ? "Crear" : "Actualizar"}
                        </Button>
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         )}
      </Container>
   );
};
