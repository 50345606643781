import BaseService from "./BaseService";
import axios from "axios";

export async function GetCustomer(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`customer/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function GetCustomerByUser(id) {
   return new Promise((resolve, reject) => {
      axios
         .get(`customer/byuser/${id}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function GetCustomerByIdentification(identification) {
   return new Promise((resolve, reject) => {
      axios
         .get(`customer/byidentification/${identification}`, {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function UpdateCustomer(entity) {
   return new Promise((resolve, reject) => {
      axios
         .put(`customer`, JSON.stringify(entity), {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function UpdateCustomerBalance(entity) {
   return new Promise((resolve, reject) => {
      axios
         .put(`customer/balance`, JSON.stringify(entity), {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export async function CreateCustomer(entity) {
   return new Promise((resolve, reject) => {
      axios
         .post("customer", JSON.stringify(entity), {
            headers: {
               "Content-Type": "application/json",
               REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
         })
         .then((response) => resolve(response.data))
         .catch((error) => reject(error));
   });
}

export default class customerService extends BaseService {
   static async getAll() {
      try {
         const baseService = new BaseService();
         return await baseService.get("customer");
      } catch (error) {
         return null;
      }
   }

   static async getById(id) {
      try {
         const baseService = new BaseService();
         return await baseService.get(`customer/${id}`);
      } catch (error) {
         return null;
      }
   }
} 