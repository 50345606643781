import React, { createContext, useState, useEffect, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";
import { GetGlobalData } from "./helpers/GetGlobalData";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import Loading from "./components/Loading";
import { MyContext } from "./context/GlobalContextProvider";
import Footer from "./components/Footer";
import { WhatsAppComponet } from "./components/WhatsAppComponent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const UserContext = createContext();

export const App = () => {
   const { updateStates } = useContext(MyContext);
   const [loading, setLoading] = useState(true);

   const LoadAuthentication = async () => {
      try {
         const getAuthenticate = await axios.get("security/authenticate", { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } });
         if (getAuthenticate.status === 200) {
            updateStates({ user: getAuthenticate.data }); 
            await GetGlobalData({ updateStates }, getAuthenticate.data.profileId);

            if (getAuthenticate.data.profileId === 3) {
               const getDeliveryPartner = await axios.get(`/deliverypartner/byuser/${getAuthenticate.data.id}`, { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } });
               if ((await getDeliveryPartner).status === 200) {
                  updateStates({ deliveryPartner: getDeliveryPartner.data });
               }
            }
         }
      } catch (Error) {
         /* console.log(Error); */
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {}, [loading]);

   useEffect(() => {
      LoadAuthentication();
   }, []);

   return loading ? (
      <Loading />
   ) : (
      <Layout>
         {/* 
            <Routes>
               {AppRoutes.map((route, index) => {
                  const { element, ...rest } = route;
                  return <Route exact key={index} {...rest} element={element} />;
               })}
               <Route path="*" element={<Navigate to="/" />} />
            </Routes> 
            */}

         <Routes>
            {AppRoutes.map((route, index) => {
               const { element, ...rest } = route;

               const ElementWithReCaptcha = <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_SITE_KEY}>{element}</GoogleReCaptchaProvider>;

               // If you only need the ReCAPTCHA on the login page
               return <Route exact key={index} {...rest} element={route.path === "/login" ? ElementWithReCaptcha : element} />;
            })}
            <Route path="*" element={<Navigate to="/" />} />
         </Routes>

         <Footer />

         <WhatsAppComponet />
      </Layout>
   );
};

export default App;
