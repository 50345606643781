import BaseService from "./BaseService";
import axios from "axios";

export async function GetNotifications() {
    try {
        const response = await axios.get("notification", {
            headers: {
                "Content-Type": "application/json",
                REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
        }); 
        return response.data;
    } catch (error) {
        /* console.log(error); */
    }
}

export async function CreateNotification(notification) {
    try {
        const response = await axios.post("notification", JSON.stringify(notification), {
            headers: {
                "Content-Type": "application/json",
                REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
            },
        });
        return response.data;
    } catch (error) {
        /* console.log(error); */
    }
}

export default class NotificationService extends BaseService {
    static async get() {
        try {
            const service = new BaseService();
            return await service.get("notification");
        } catch (error) {
            return null;
        }
    }
}
