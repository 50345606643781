import React, { useContext, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import imgRosaGas from "../assets/img/rosagas.png";
import axios from "axios";
import { MyContext } from "../context/GlobalContextProvider";
import { Container, Button, TextField, Alert } from "@mui/material";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { GetGlobalData } from "../helpers/GetGlobalData";

export const LoginPage = () => {
   const [email, setEmail] = useState();
   const [password, setPassword] = useState();
   const [checkedRememberme, setCheckedRememberme] = useState(false);
   const [rememberme, setRememberme] = useState("false");
   const [alert, setAlert] = useState(false);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const { updateStates } = useContext(MyContext);
   const { executeRecaptcha } = useGoogleReCaptcha();

   const handleReCaptchaVerify = useCallback(async () => {
      if (!executeRecaptcha) {
         /* console.log("Execute recaptcha not yet available"); */
         return;
      }

      const token = await executeRecaptcha("login");
      /* console.log("valor del reCAPTCHA:", token); */
   }, [executeRecaptcha]);

   /* const HandleRememberme = () => {
        if (checkedRememberme) {
           setCheckedRememberme(false);
           setRememberme("false");
        } else {
           setCheckedRememberme(true);
           setRememberme("true");
        }
     }; */

   async function HandleSubmit(event) {
      event.preventDefault();
      setLoading(true);

      //await handleReCaptchaVerify();

      try {
         const responseAuthenticate = await axios.post(
            "security/authenticate",
            {
               Username: email,
               Password: password,
               rememberme: rememberme,
            },
            {
               headers: {
                  "Content-Type": "application/json",
                  REACT_APP_API_KEY: process.env.REACT_APP_API_KEY,
               },
            }
         );

         if (responseAuthenticate.status === 200) {
            /* loadObjects(); */

            var userData = JSON.parse(responseAuthenticate.data.Result); 
            if (userData !== null && (userData.profileId === 1 || userData.profileId === 4)) {
               updateStates({ user: userData });
               await GetGlobalData({ updateStates }, userData.profileId);

               /* const getDeliveryPartnerResponse = await axios.get("deliverypartner/byuser/" + userData.id, { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } });
               if (getDeliveryPartnerResponse.status === 200) {
                  updateStates({ deliveryPartner: getDeliveryPartnerResponse.data.Result });
                  await LoadProducts();
                  navigate("/orders");
               } */

               switch (userData.profileId) {
                  case 3:
                     const getDeliveryPartnerResponse = await axios.get("deliverypartner/byuser/" + userData.id, { headers: { "Content-Type": "application/json", REACT_APP_API_KEY: process.env.REACT_APP_API_KEY } });
                     if (getDeliveryPartnerResponse.status === 200) {
                        updateStates({ deliveryPartner: getDeliveryPartnerResponse.data.Result });
                        navigate("/orders");
                     }
                     break;

                  default:
                     navigate("/Home");
                     break;
               }
            } else {
               setAlert(true);
            }
         } else { 
            setAlert(true);
         }
      } catch (error) { 
         setAlert(true);
      } finally {
         setLoading(false);
      }
   }

   /*    useEffect(() => {
        console.log("useEffect", user);
     }, [user]); */

   return (
      <Container maxWidth="sm" className="mainContainerMinHeight">
         <form
            className="max-width-sm"
            method="post"
            onSubmit={(event) => {
               //event.preventDefault();
               //handleReCaptchaVerify();
               HandleSubmit(event);
            }}
         >
            <img className="mt-5 mb-5 img-fluid mx-auto d-block scale-in-center" src={imgRosaGas} alt="Rosa Gas" width="380" height="170" />

            <TextField
               fullWidth
               id="textFieldEmail"
               label="Correo electrónico"
               variant="outlined"
               onChange={(event) => {
                  setEmail(event.target.value);
               }}
               sx={{ marginBottom: 2 }}
               required
               inputProps={{ autoComplete: 'current-email' }}
            />

            <TextField
               fullWidth
               id="textFieldPassword"
               label="Contraseña"
               variant="outlined"
               type="password"
               onChange={(event) => {
                  setPassword(event.target.value);
               }}
               sx={{ marginBottom: 2 }}
               required
               inputProps={{ autoComplete: 'current-password' }}
            />

            {alert ? (
               <>
                  <Alert
                     severity="error"
                     sx={{ marginBottom: 2 }}
                     onClose={() => {
                        setAlert(false);
                     }}
                  >
                     Contraseña o Nombre de Usuario no válidos!
                  </Alert>
               </>
            ) : null}

            {/* <div className="checkbox mb-3">
               <label>
                  <input
                     type="checkbox"
                     value="remember-me"
                     placeholder="Recuérdame"
                     checked={checkedRememberme}
                     onChange={() => {
                        HandleRememberme();
                     }}
                  />
                  &nbsp;Recuérdame
               </label>
            </div> */}

            {loading ? (
               <Button fullWidth variant="contained" size="large" disabled>
                  Iniciar sesión &nbsp;
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
               </Button>
            ) : (
               <Button fullWidth variant="contained" size="large" type="submit">
                  Iniciar sesión
               </Button>
            )}
         </form>
      </Container>
   );
};
