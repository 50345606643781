import { generateRandomPassword } from "../helpers/Crypto";

export const UsersProfileValues = Object.freeze({ ADMIN: 1, CUSTOMER: 2, DELIVERY: 3 });

export class User {
   constructor({ token = "", id = 0, profileId = "", username = "", password = "" } = {}) {
      this.token = token;
      this.id = id;
      this.profileId = profileId;
      this.username = username;
      this.password = password || generateRandomPassword();
   } 
}
