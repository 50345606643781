import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { Container, IconButton, Button, Box, Checkbox, FormControl, FormControlLabel, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack, InputLabel, InputAdornment, OutlinedInput } from "@mui/material";
import { AddRounded, EditRounded, SearchRounded } from "@mui/icons-material";
import { GetAllDistributionCenters } from "../services/DistributionCenterService";
import { SnackbarContext } from "../components/SnackbarProviderWrapper";

export const DistributionCentersPage = () => {
   const snackbar = useContext(SnackbarContext);

   const [listDistributionCenter, setlistDistributionCenter] = useState(null);
   const [DistributionCenterStatus, setDistributionCenterStatus] = useState(null);
   const [filterDistributionCenter, setFilterDistributionCenter] = useState("All");
   const [loading, setLoading] = useState(true);
   const [search, setSearch] = useState("");

   const navigate = useNavigate();

   const handleSearchChange = (event) => {
      setSearch(event.target.value);
   };

   async function LoadDistributionCenters() {
      const response = await GetAllDistributionCenters();
      if (response.Result !== null) {
         setlistDistributionCenter(response.Result);
      } else {
         snackbar.current.enqueueSnackbar(`Error al obtener los Centros de Distribucion`, {
            variant: "error",
         });
      }
      setLoading(false);
   }

   useEffect(() => {
      LoadDistributionCenters();
   }, []);

   return (
      <Container maxWidth="xl" className="mainContainerMinHeight">
         <Typography component="h2" align="center" variant="h4" m={1}>
            Centros de Distribución
         </Typography>

         <FormControl fullWidth variant="outlined" size="small" sx={{ marginTop: 1, marginBottom: 1 }}>
            <InputLabel htmlFor="outlined-adornment-search">Buscar</InputLabel>
            <OutlinedInput
               id="outlined-adornment-search"
               onChange={handleSearchChange}
               endAdornment={
                  <InputAdornment position="end">
                     <IconButton edge="end">
                        <SearchRounded />
                     </IconButton>
                  </InputAdornment>
               }
               label="Buscar"
            />
         </FormControl>

         <IconButton
            title="Crear Sucursal"
            color="primary"
            sx={{ marginBottom: 1 }}
            onClick={() => {
               navigate("/distribution-center/0");
            }}
         >
            <AddRounded />
         </IconButton>

         {listDistributionCenter === null ? null : (
            <TableContainer component={Paper}>
               <Table size="small" aria-label="Centros de Distribución" stickyHeader>
                  <TableHead>
                     <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Estado</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Dirección</TableCell>
                        <TableCell align="center">Opcción</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {listDistributionCenter.map((item, index) => {
                        if (search === "" || item.id.toString().includes(search) || item.name.toLowerCase().includes(search.toLowerCase()) || item.address.toLowerCase().includes(search.toLowerCase())) {
                           return (
                              <TableRow key={item.id}>
                                 <TableCell>{item.id}</TableCell>
                                 <TableCell>
                                    <FormControlLabel control={<Checkbox defaultChecked={item.active} size="small" disabled />} label={item.active ? "Activo" : "Inactivo"} />
                                 </TableCell>
                                 <TableCell component="th" scope="row">
                                    {item.name}
                                 </TableCell>

                                 <TableCell>{item.address}</TableCell>
                                 <TableCell align="center">
                                    <IconButton
                                       title="Editar Sucursal"
                                       color="primary"
                                       onClick={() => {
                                          navigate(`/distribution-center/${item.id}`);
                                       }}
                                    >
                                       <EditRounded />
                                    </IconButton>
                                 </TableCell>
                              </TableRow>
                           );
                        } else {
                           return null;
                        }
                     })}
                  </TableBody>
               </Table>
            </TableContainer>
         )}
      </Container>
   );
};
