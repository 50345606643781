import React, { useState, createContext } from "react";

export const useMyStates = () => {
   const [user, setUser] = useState(null);
   const [customer, setCustomer] = useState(null);
   const [order, setOrder] = useState(null);
   const [orderWalletStatus, setOrderWalletStatus] = useState(null);
   const [products, setProducts] = useState(null);
   const [deliveryPartner, setDeliveryPartner] = useState(null);
   const [orderDeliveryStatus, setOrderDeliveryStatus] = useState(null);
   const [distributionCenter, setDistributionCenter] = useState(null); 

   return {
      states: { user, customer, order, orderWalletStatus, products, deliveryPartner, orderDeliveryStatus, distributionCenter },
      setters: { setUser, setCustomer, setOrder, setOrderWalletStatus, setProducts, setDeliveryPartner, setOrderDeliveryStatus, setDistributionCenter },
   };
};

export const MyContext = createContext(null);

export const MyProvider = ({ children }) => {
   const { states, setters } = useMyStates();

   const updateStates = (newStates) => {
      for (const [stateName, newValue] of Object.entries(newStates)) {
         const setterName = `set${stateName.charAt(0).toUpperCase()}${stateName.slice(1)}`;
         if (typeof setters[setterName] === "function") {
            setters[setterName](newValue);
         } else {
            console.error(`Error: No setter function found for state '${stateName}'.`);
         }
      }
   };

   const resetStates = () => {
      console.log("resetStates");
      for (const setterName of Object.keys(setters)) {
         if (typeof setters[setterName] === "function") {
            setters[setterName](null);
         }
      }
   };

   return <MyContext.Provider value={{ states, updateStates, resetStates }}>{children}</MyContext.Provider>;
};
