import React, { Component } from "react";
import { ResponsiveAppBar } from "./ResponsiveAppBar";
import Container from "@mui/material/Container";

export class Layout extends Component {
   static displayName = Layout.name;

   render() {
      return (
         <div>
            <div style={{ background: "rgb(237, 10, 143)" }}>
               <Container maxWidth="xl">
                  <div className="d-flex justify-content-between align-items-center text-white pt-1 pb-1">
                     <div className="me-auto">
                        <label className="">
                           <i className="bi bi-envelope-at-fill" style={{ color: "#fff" }}></i>
                           {""} ventas@rosagas.com
                        </label>
                     </div>
                     <div>
                        <label className="">
                           <a href="https://wa.me/50689221111" className="text-decoration-none text-white" target="_blank" rel="noreferrer noopener">
                              <i className="bi bi-whatsapp" style={{ color: "#fff" }}></i>
                              {""} +506 8922 1111
                           </a>
                        </label>
                     </div>
                  </div>
               </Container>
            </div>

            <ResponsiveAppBar />

            <div>{this.props.children}</div>
         </div>
      );
   }
}
