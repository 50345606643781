export class Customer {
   constructor({ id = 0, active = true, userId = 0, identification = "", firstName = "", lastName = "", balance = 0, phoneNumber = "", picture = "" } = {}) {
      this.id = id;
      this.active = active;
      this.userId = userId;
      this.identification = identification;
      this.firstName = firstName;
      this.lastName = lastName;
      this.balance = balance;
      this.phoneNumber = phoneNumber;
      this.picture = picture;
   }
}
